import React, { useCallback, useEffect, useState } from 'react';
import { ethers } from 'ethers';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router';
import { useAddress, useContract, useContractRead, useContractWrite, useBalance} from "@thirdweb-dev/react";
import { CONTRACT_ADDRESS, TOKEN_ADDRESS, ORACLE_ADDRESS, USDC_ADDRESS } from "../../const/addresses";
const Stake = () => {

    const navigate = useNavigate();
    const address = useAddress();
    const { contract } = useContract(CONTRACT_ADDRESS);
    const {contract: usdcContract} = useContract(USDC_ADDRESS);
    const { contract: tokenContract} = useContract(TOKEN_ADDRESS);

    const { data: userInfo, isLoading: isUserInfoLoading } = useContractRead(contract, "userInfo", [address]);

    

    const { data: nativeBalance, isLoading: isNativeBalanceLoading } = useBalance();

    const { data: tokenBalance, isLoading: isTokenBalanceLoading } = useBalance(TOKEN_ADDRESS);

    const { contract: oracleContract } = useContract(ORACLE_ADDRESS);

    const { mutateAsync: estimateAmountOut} = useContractWrite(oracleContract, "estimateAmountOut");

    const[ usdcValue, setUsdcValue] = useState(0);
    // console.log(usdcValue);
    const [isStaked, setIsStaked] = useState(false);

    const [isEstimating, setIsEstimating] = useState(false);

    const attemptEstimateAmountOut = useCallback(async (retries = 3, delay = 3000) => {
        setIsEstimating(true);
        let lastError= null;

        for (let i = 0; i < retries; i++) {
            try {
                const tokenAmount = tokenBalance.value;
                const amountString = tokenAmount.toString();
                const weiValue = ethers.utils.parseUnits(amountString, 'wei');
                const data = await estimateAmountOut({ args: [TOKEN_ADDRESS, weiValue, 5]});
                const stringValue = ethers.BigNumber.from(data);
                const numberValue = ethers.utils.formatUnits(stringValue, 6);
                const amountUsdc = parseFloat(numberValue).toFixed(3);
                setUsdcValue(amountUsdc);
                setIsEstimating(false);
                return;
            } catch (error) {
                lastError = error;
                await new Promise((resolve) => setTimeout(resolve, delay));
            }
        }
        setIsEstimating(false);
        console.error("Failed to estimate amount out:", lastError);
    }, [tokenBalance, estimateAmountOut]);

    useEffect(() => {
        if(!isTokenBalanceLoading && tokenBalance) {
            attemptEstimateAmountOut();
        }
    }, [isTokenBalanceLoading, tokenBalance, attemptEstimateAmountOut]);

    const [nativeValue, setNativeValue] = useState(0);
    const [tokenValue, setTokenValue] = useState(0);

    useEffect(() => {
        if(!isTokenBalanceLoading && tokenBalance) {
            const myTokenBalance = tokenBalance.displayValue;
            const myPLGBalance = parseFloat(myTokenBalance).toFixed(3);
            setTokenValue(myPLGBalance);
        }
    }, [isTokenBalanceLoading, tokenBalance]);

    useEffect(() => {
        if(!isNativeBalanceLoading && nativeBalance) {
            const myNativeBalance = nativeBalance.displayValue;
            const myMatic = parseFloat(myNativeBalance).toFixed(3);
            setNativeValue(myMatic);
        }
    }, [isNativeBalanceLoading,nativeBalance]);

    useEffect(() => {
        if(userInfo && userInfo.totalStake > 0) {
            setIsStaked(true);
        } else {
            setIsStaked(false);
        }
    }, [isUserInfoLoading,userInfo]);
    const [inputError, setInputError] = useState('');

    const handleInputChange = (e) => {
        const inputAmount = e.target.value;
        setUsdcAmount(inputAmount);
        if (parseFloat(inputAmount) > parseFloat(usdcValue)) {
            setInputError('Input amount exceeds available USDC value.');
        } else {
            setInputError('');
        }
    };
    
    const [gasError, setGasError] = useState('');
    useEffect(() => {
        const checkGasFee = async () => {
            const requiredGasFee = ethers.utils.parseUnits('0.01', 'ether'); // Gas fee in wei for 0.01 Matic
            if (nativeBalance && nativeBalance.value.lt(requiredGasFee)) {
                setGasError("Insufficient Matic for the transaction fee. You need at least 0.01 Matic.");
            } else {
                setGasError(''); // Clear error if the condition is no longer met
            }
        };
    
        checkGasFee();
    }, [nativeBalance]); // This effect depends on nativeBalance and will re-run when it changes



    const { mutateAsync: stake, isLoading: isStaking} = useContractWrite(contract, "stake")
    const [usdcAmount, setUsdcAmount] = useState('');

    const handleStake = async () => {
      try {
        
        const inputAmount = ethers.utils.parseUnits(usdcAmount, 6); // Adjust based on token's decimals
        // Check Allowance
        const currentAllowance = await tokenContract?.erc20.allowance(CONTRACT_ADDRESS, address);
        console.log(currentAllowance);
        const requiredAllowance = ethers.utils.parseUnits('100', 18); // Ensure this is a BigNumber with the correct decimals
        console.log(`Formatted Allowance in Wei: ${requiredAllowance.toString()}`);
    
        // Compare using BigNumber comparison methods
        if (currentAllowance.value.lt(requiredAllowance)) {
          // If current allowance is less than required allowance, set a new allowance
          await tokenContract?.erc20.setAllowance(CONTRACT_ADDRESS, 10000);
        }
        const { receipt } = await stake({ args: [inputAmount] });
        const transactionHash = receipt.transactionHash;
        // console.log(receipt);
        toast.success(
          `Staking successful: Transaction Hash: ${transactionHash}`
        );
        navigate("/history");
      } catch (error) {
        toast.error(`Staking failed: ${error.reason}`);
      }
    };

    const [amountOut, setAmountOut] = useState('');
    const handleAmountOut = useCallback(async () => {
      try {
          const amountIn = ethers.utils.parseUnits(usdcAmount, 6);
          const outPut = await estimateAmountOut({ args: [USDC_ADDRESS, amountIn, 5]});
          const plgAmount = ethers.utils.formatUnits(outPut, 18);
          const amountOut = parseFloat(plgAmount).toFixed(2);
          
          if (usdcAmount !== '') {
              setAmountOut(amountOut);
          } else {
              setAmountOut('');
          }
      } catch (error) {
          console.error("Failed to estimate amount out:", error);
      }
    }, [usdcAmount, estimateAmountOut]);

    useEffect(() => {
      if(usdcAmount !== '') {
        handleAmountOut();
      }
    }, [usdcAmount, handleAmountOut]);

    return (
      <section className="staking-area">
        <div className="container">
          <div className="row items mb-4">
            <div className="col-12">
              <div className="card tier-card prev-project-card">
                <div className='d-flex justify-content-center mt-4'>    
                    <img src={"/img/stake2.gif"} style={{width: '300px'}} alt="polygon" />
                </div> 
              <h6 className="m-0 mb-3">My Balances</h6>
                <div className="project-content d-flex justify-content-around align-items-center">
                  <div className="content">
                    <h6 className="m-0">{tokenValue}</h6>
                    <h6 className="mt-1 mb-0">$PLG</h6>
                  </div>
                  <span className='text-lg'>≈</span>
                  <div className="content">
                    <h6 className="m-0"> {usdcValue}</h6>
                    <h6 className="mt-1 mb-0">USDT</h6>
                  </div>
                  <div className="content">
                    <h6 className="m-0">{nativeValue}</h6>
                    <h6 className="mt-1 mb-0">Matic</h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-12">
            <div className="card no-hover tier-card single-staking">
              <h4 className="m-0">Stake $PLG</h4>
              <div className="input-box my-4">
                <div className="input-area d-flex flex-column flex-md-row">
                  <div className="input-text">
                    <input
                      type="number"
                      max={usdcValue}
                      placeholder="0" //{"Input Stake Amount"}
                      onChange={handleInputChange}
                      readOnly={isEstimating}
                    />
                    <a>USDT</a>
                  </div>
                  <p className="text-center">
                    ≈ <span>{amountOut} $PLG</span>
                  </p>
                  <button
                    className="btn input-btn mt-4 mt-md-0 ml-md-3 active btn-primary"
                    onClick={handleStake}
                    disabled={!!gasError || !!inputError || isStaking} // Disable button if there's a gas error or input error
                  >
                    {" "}
                    Stake
                  </button>
                </div>
                
              </div>
              {inputError && <p className="text-warning">*{inputError}*</p>}
                  {/* // Display the gas error message here */}
                  {gasError && <p className="text-danger">*{gasError}*</p>}
                  <p className="text-success">
                    *Min. Stake Amount: 1 USDT Max. Stake Amount : 5000 USDT*
                  </p>
            </div>
          </div>
        </div>
      </section>
    );
}

export default Stake;