import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { ThirdwebProvider, metamaskWallet, walletConnect, trustWallet, useSwitchAccount} from "@thirdweb-dev/react";
import './index.css';
import App from './App';
import { Polygon } from "@thirdweb-dev/chains";

const queryClient = new QueryClient()
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThirdwebProvider
  clientId={process.env.REACT_APP_PROJECT_ID}
  activeChain={Polygon}
  supportedWallets={[
    metamaskWallet({ recommended: true }),
    walletConnect({ recommended: true }),
    trustWallet({ recommended: true }),
  ]}
  
  >
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
    <App />
    </QueryClientProvider>
  </React.StrictMode>
  </ThirdwebProvider>
);
