import React, { Component } from 'react';

const initData = {
    sub_heading: "PLEDGE-STAKE",
    heading: "The Next Generation Staking & liquidity provider protocol",
    content: "Ecosystem to provide liquidity for smooth trading & value creation with amazing staking rewards"
}

class Hero extends Component {
    state = {
        initData: {}
    }
    componentDidMount(){
        this.setState({
            initData: initData
        })
    }
    handlewpButtonClick = () => {
        alert("Whitepaper Updating soon");
    }
    render() {
        return (
            <section className="hero-section">
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                    <div className="col-12 col-md-12 col-lg-12 text-center">
                        {/* Hero Content */}
                        <div className="hero-content">
                        <div className="intro text-center mb-5">
                            <h3 className="intro" style={{color: 'blue'}}>{this.state.initData.sub_heading}</h3>
                            <h1 className="mt-4">{this.state.initData.heading}</h1>
                            <p>{this.state.initData.content}</p>
                        </div>
                        {/* Buttons */}
                        <div className="button-group">
                            <button className="btn btn-bordered active smooth-anchor" href="#explore"><i className="icon-rocket mr-2" />Join Now</button>
                            <button className="btn btn-bordered-white" onClick={this.handlewpButtonClick}><i className="icon-note mr-3" />Whitepaper</button>
                            {/* <button className="btn btn-bordered active d-inline-block" onClick={this.handleButtonClick}><i className="{this.state.data.btnIcon}" />Purchase $PLG</button> */}
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Hero;