import React, { Component, useEffect, useState } from 'react';
import { useAddress, useContract, useContractRead, useContractWrite, useBalance,useTokenSupply} from "@thirdweb-dev/react";
import { CONTRACT_ADDRESS, TOKEN_ADDRESS, ORACLE_ADDRESS } from "../../const/addresses";
import { ethers } from "ethers";
const ProjectSingle = () => {
    const{contract} = useContract(CONTRACT_ADDRESS);

    const [pledgePrice, setPledgePrice] = useState(0);
    const {data: currentAirdrop, isLoading: isCurrentAirdropLoading} = useContractRead(contract, "getCurrentActiveAirDropIndex");
    useEffect(() => {
        if (!isCurrentAirdropLoading && currentAirdrop) {
          const pricePledge = ethers.utils.formatUnits(currentAirdrop[1], 6);
          console.log(pricePledge);
          const formattedPrice = parseFloat(pricePledge).toFixed(4);
          setPledgePrice(formattedPrice);
        }
      }, [isCurrentAirdropLoading, currentAirdrop]); // Added dependency array here
    const {contract: tokenContract} = useContract(TOKEN_ADDRESS);
    const { data, isLoading, error } = useTokenSupply(tokenContract);

    const formattedData = data ? {
        name: data.name,
        symbol: data.symbol,
        decimals: data.decimals,
        currentSupply: parseInt(data.displayValue).toFixed(0)
    } : {
        name: '',
        symbol: '',
        decimals: '',
        currentSupply: ''
    };

    console.log(formattedData);
    

    const initData = {
        img: "/img/thumb_1.png",
        logo: "/img/plg.png",
        blockchain: "/img/matic.png",
        tokenlogo: "/img/matic.png",
        title: "Pledge",
        name: "Pledge",
        symbol: "$PLG",
        heading: "Project Summery",
        stakedet: "Staking Details",
        plandet: "Reward Plan",
        maxsupply: "210M",
        price: "3.3$PLG/1Matic",
        currentsupply: "36K $PLG",
        supplymech: "Mint on Demand",
        progress: "25%",
        mecha: "0/100,069 MECHA",
        busd: "0 BUSD",
        actionBtn: "Join Now",
        video_img: "/img/thumb_6.png",
        video_icon: "fa-solid fa-play",
        video_link: "https://www.youtube.com/watch?v=fzBTvDraO5U"
    }

    const summaryData = {
        title: "Pledge-Stake",
        img: "/img/move.gif",
        content_1: "PledgeStake is staking platform for Pledge($PLG) token formed on time tested polygon(Matic) blockchain and aimed to provide strong transactional experience for users. Pledge token is formed to demonstrate liquidity programs in real manners.",
        content_2: "This will guide through how the liquidity works in the value creation of a token. This will provide Amazing staking returns to users in small periods of staking pledge tokens.",
        content_3: "Stake your $PLG on PledgeStake and enjoy amazing returns on your stake."
    }
    
    const overviewData = {
        list_heading: "Stake Amount & Rewards",
        content: "Reward plan is really simple and automated. There will be no interference by anyone who controls. All rewards are contract based.",
    }
    
    const tokenmetricsData = {
        title: "Token Metrics",
        content: "Even the all-powerful Pointing has no control about the blind texts it is an almost unorthographic life One day however a small line of blind text by the name of Lorem Ipsum decided to leave for the far World of Grammar."
    }
    
    const roadmapData = {
        title: "Roadmap",
        content_1: "Far far away, behind the word mountains, far from the countries Vokalia and Consonantia, there live the blind texts. Separated they live in Bookmarksgrove right at the coast of the Semantics, a large language ocean.",
        content_2: "A small river named Duden flows by their place and supplies it with the necessary regelialia. It is a paradisematic country, in which roasted parts of sentences fly into your mouth."
    }
    
    const socialData = [
        {
            id: "1",
            link: "twitter",
            icon: "fab fa-twitter"
        },
        {
            id: "2",
            link: "telegram",
            icon: "fab fa-telegram"
        },
        {
            id: "3",
            link: "globe",
            icon: "fas fa-globe"
        },
        {
            id: "4",
            link: "discord",
            icon: "fab fa-discord"
        }
    ]
    
    const overviewList = [
        {
            id: "1",
            content: "Minimum Stake: 1 USD",
        },
        {
            id: "2",
            content: "Maximum Stake: 5000 USD",
        },
        {
            id: "3",
            content: "Stake Round: 30 Days",
        },
        {
            id: "4",
            content: "Round Reward: 3% to 13% of Staked USD.",
        },
        {
            id: "5",
            content: "35% of earnings will be deducted from the staking amount for early unstaking.",
        },
        {
            id: "6",
            content: "Direct Referral Reward: 3%",
        },
        {
            id: "11",
            content: "*For more information and to see terms & conditions please refer to stake plan documents.",
        }
    ]
    
    const tokenList = [
        {
            id: "1",
            title: "Fundraised:",
            content: "$500,000",
        },
        {
            id: "2",
            title: "Platfrom Raise:",
            content: "$120,000 + $5,000",
        },
        {
            id: "3",
            title: "Price:",
            content: "$0.05",
        },
        {
            id: "4",
            title: "Lock-up:",
            content: "25% unlocked on TGE, 4 months cliff, then 5% on a monthly basis",
        }
    ];

    return (
        <section className="item-details-area" id='explore'>
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        {/* Intro */}
                        <div className="intro d-flex justify-content-between align-items-end m-0 mb-4">
                            <div className="intro-content">
                                <h3 className="mt-3 mb-0">{ initData.heading }</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-between">
                    <div className="col-12 col-lg-12 col-xl-12 ">
                        {/* Project Card */}
                        <div className="card project-card no-hover">
                            <div className="media">
                                <img className="card-img-top avatar-max-lg" src={ initData.img } alt="" />
                                <div className="media-body ml-4">
                                    <h4 className="m-0">{ initData.title }</h4>
                                    <div className="countdown-times">
                                        <h6 className="my-2">$PLG</h6>
                                    </div>
                                </div>
                            </div>
                            {/* Project Body */}
                            <div className="card-body">
                                <div className="items">
                                    {/* Single Item */}
                                    <div className="single-item">
                                        <span>Token Name</span>
                                        <span> {formattedData.name}</span>
                                    </div>
                                    {/* Single Item */}
                                    <div className="single-item">
                                        <span>Token Symbol</span>
                                        <span> { formattedData.symbol }</span>
                                    </div>
                                    <div className="single-item">
                                        <span>Token Decimals</span>
                                        <span> { formattedData.decimals }</span>
                                    </div>
                                    
                                    <div className="single-item">
                                        <span>Token Logo</span>
                                        <span> <img src={ initData.logo }  style={{width: '20px'}} alt="plg" /></span>
                                    </div>
                                    {/* Single Item */}
                                    <div className="single-item">
                                        <span>Price</span>
                                        <span> 1 $PLG ≈ {pledgePrice} USDT</span>
                                    </div>
                                    {/* Single Item */}
                                    <div className="single-item">
                                        <span>Max. Supply</span>
                                        <span> { initData.maxsupply }</span>
                                    </div>                                        
                                    {/* Single Item */}
                                    <div className="single-item">
                                        <span>Current Supply</span>
                                        <span> { formattedData.currentSupply }</span>
                                    </div>
                                    <div className="single-item">
                                        <span>Supply Mechanism</span>
                                        <span> { initData.supplymech }</span>
                                    </div>
                                </div>
                            </div>
                            {/* Project Footer */}
                            <div className="project-footer d-flex align-items-center mt-4 mt-md-5">
                                <button className="btn btn-bordered-white btn-smaller">{ initData.actionBtn }</button>
                                {/* Social Share */}
                                <div className="social-share ml-auto">
                                    <ul className="d-flex list-unstyled">
                                        {socialData.map((item, idx) => {
                                            return (
                                                <li key={`sd_${idx}`}>
                                                    <a href="/#">
                                                        <i className={ item.icon } />
                                                    </a>
                                                </li>
                                            );
                                        })}
                                    </ul>
                                </div>
                            </div>
                            {/* Blockchain Icon */}
                            <div className="blockchain-icon">
                                <img src={ initData.blockchain } alt="polygon" />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-12">
                        {/* Intro */}
                        <div className="intro d-flex justify-content-between align-items-end m-0 mb-3">
                            <div className="intro-content">
                                <h3 className="mt-3 mb-0">{ initData.stakedet }</h3>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="col-md-12 col-lg-12 col-xl-12 items  mt-lg-0">                           
                        <div className="card project-card single-item-content no-hover item ml-lg-4 ">
                            <h3 className="d-flex justify-content-center m-0">{summaryData.title}</h3>
                                <div className='d-flex justify-content-center mt-4'>    
                                    <img src={ summaryData.img } style={{width: '300px'}} alt="polygon" />
                                </div> 
                            <p>{summaryData.content_1}</p>
                            <p>{summaryData.content_2}</p>
                            <p>{summaryData.content_3}</p>
                        </div>
                            <div className="row">
                                <div className="col-12">
                                    {/* Intro */}
                                    <div className="intro d-flex justify-content-between align-items-end m-0">
                                        <div className="intro-content">
                                            <h3 className="mt-3 mb-0">{ initData.plandet }</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        <div className="card project-card single-item-content no-hover item ml-lg-4">
                            <p className="mb-0">{overviewData.content}</p>
                            <h4>{overviewData.list_heading}</h4>
                            <ul>
                                {overviewList.map((item, idx) => {
                                    return (
                                        <li key={`odl_${idx}`}>{item.content}</li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ProjectSingle;