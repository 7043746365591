import React, { Component } from 'react';
import axios from 'axios';

const BASE_URL = "https://my-json-server.typicode.com/themeland/gameon-json/staking";

class Splinter extends Component {
    state = {
        data: {},
        tabData: [],
        tabContent: [],
        features: []
    }
    componentDidMount(){
        axios.get(`${BASE_URL}`)
            .then(res => {
                this.setState({
                    data: res.data,
                    tabData: res.data.tabData,
                    tabContent: res.data.tabContent,
                    features: res.data.features
                })
                // console.log(this.state.data)
            })
        .catch(err => console.log(err))
    }
    render() {
        return (
            <section className="staking-area">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-12">
                            <div className="card no-hover staking-card single-staking">
                                <h3 className="m-0">Split Your Rewards</h3>
                                <div className="input-box my-4">
                                    <div className="input-area d-flex flex-column flex-md-row mb-3">
                                        <div className="input-text">
                                            <input type="text" placeholder={0.00} />
                                            <a href="#">Max</a>
                                        </div>
                                        <a href="#" className="btn input-btn mt-2 mt-md-0 ml-md-3">{this.state.data.input_btn_1}</a>
                                    </div>
                                    <div className="input-area d-flex flex-column flex-md-row">
                                        <div className="input-text">
                                            <input type="text" placeholder={0.00} />
                                            <a href="#">Max</a>
                                        </div>
                                        <a href="#" className="btn input-btn mt-2 mt-md-0 ml-md-3">{this.state.data.input_btn_2}</a>
                                    </div>
                                </div>
                                <span>{this.state.data.content}</span>
                                <span className="mt-3"><strong>{this.state.data.note}</strong></span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Splinter;