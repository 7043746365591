import { useAddress, useContract, useContractRead, useContractWrite } from "@thirdweb-dev/react";
import { ethers } from 'ethers';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CONTRACT_ADDRESS } from "../../const/addresses";
import React, { useState, useEffect } from 'react';

const History = () => {
  const address = useAddress();
  const { contract } = useContract(CONTRACT_ADDRESS);

  const { data, isLoading } = useContractRead(contract, "getStakeInfo", [address]);
  const stakes = data ? data[0].map((amount, index) => ({
    amount: ethers.utils.formatUnits(amount, 6),
    startTime: new Date(data[1][index].toNumber() * 1000).toLocaleString(),
    isCompleted: data[3][index] ? 'Yes' : 'No',
    isUnStaked: data[4][index] ? 'Yes' : 'No',
  })) : [];

  
  const { data: roundRewards, isLoading: isRoundRewardsLoading } = useContractRead(contract, "viewRewards", [address]);
  // console.log(roundRewards);
  const rewards = roundRewards ? roundRewards[0].map((roundNumber, index) => ({
    roundNumber: roundNumber.toNumber(),
    roundReward: ethers.utils.formatUnits(roundRewards[1][index], 6),
    rewards: ethers.utils.formatUnits(roundRewards[2][index], 6),
  })) : [];
  const { mutateAsync: claimRewards, isLoading: isClaiming } = useContractWrite(contract, "claimRewards");
  const handleClaimRewards = async (index) => {
      try {
        const { receipt } = await claimRewards({ args: [index] });
        const transactionHash = receipt.transactionHash;
        toast.success(`Rewards claimed: Transaction Hash: ${transactionHash}`);
      } catch (error) {
        toast.error(`Claiming rewards failed: ${error.reason}`);
      }
  };

  const { mutateAsync: unStake, isLoading: isUnStaking } = useContractWrite(contract, "unStake");
  const handleUnStake = async (index) => {
    try {
      const { receipt } = await unStake({ args: [index] });
      const transactionHash = receipt.transactionHash;
      toast.success(`Unstaking successful: Transaction Hash: ${transactionHash}`);
    } catch (error) {
      toast.error(`Unstaking failed: ${error.reason}`);
    }
  }

  const combinedData = stakes.map((stake, index) => ({
    ...stake,
    roundNumber: rewards[index] ? rewards[index].roundNumber : 'N/A',
    roundReward: rewards[index] ? rewards[index].roundReward : 'N/A',
    rewards: rewards[index] ? rewards[index].rewards : 'N/A',
    // isCompleted: rewards[index] && rewards[index].roundNumber === 24 ? 'Yes' : stake.isCompleted,
  }));

  return (
    <section className="leaderboard-area">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="table-responsive">
              {/* <table className="table token-content table-borderless responsive-table"> */}
              <table className="table token-content table-borderless responsive-table">
                <thead>
                  <tr>
                    <th scope="col" style={{ width: '5%' }}>SR.</th>
                    <th scope="col" style={{ width: '8%' }}>Amount</th>
                    <th scope="col" style={{ width: '12%' }}>Start</th>
                    {/* <th scope="col" style={{ width: '9%' }}>Complete?</th> */}
                    {/* <th scope="col" style={{ width: '9%' }}>Unstake?</th> */}
                    <th scope="col" style={{ width: '7%' }}>Rounds</th>
                    <th scope="col" style={{ width: '10%' }}>Current Reward</th>
                    <th scope="col" style={{ width: '10%' }}>Total Rewards</th>
                    <th scope="col" style={{ width: '15%' }}>Action</th>
                    <th scope="col" style={{ width: '15%' }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {combinedData.map((item, index) => (
                    <tr key={index}>
                      <td style={{ width: '5%' }}>{index + 1}</td>
                      <td style={{ width: '8%' }}>{item.amount}</td>
                      <td style={{ width: '12%', fontSize: '9.5px', padding: '20px' }}>{item.startTime}</td>
                      {/* <td style={{ width: '9%' }}>{rewards[index] && rewards[index].roundNumber === 24 ? 'Yes' : 'No'}</td> */}
                      {/* <td style={{ width: '9%' }}>{item.isUnStaked}</td> */}
                      <td style={{ width: '7%' }}>{item.roundNumber}</td>
                      <td style={{ width: '10%' }}>{item.roundReward}</td>
                      <td style={{ width: '10%' }}>{item.rewards}</td>
                      <td style={{ width: '15%' }}>
                        <button
                         className="btn btn-bordered-pill-green"
                         onClick={() => handleClaimRewards(index)}
                         disabled={isClaiming || item.isCompleted === 'Yes' || item.isUnStaked === 'Yes' } // Disable button if there's a gas error or input error
                         >
                          {item.isCompleted === 'Yes' ? 'Completed' : 'Claim'}
                         </button>
                      </td>
                      <td style={{ width: '15%' }}>
                        <button
                         className="btn btn-bordered-pill-red"
                         onClick={() => handleUnStake(index)}
                         disabled={isUnStaking || item.isUnStaked === 'Yes'|| item.isCompleted === 'Yes'} // Disable button if there's a gas error or input error
                         >
                          {item.isUnStaked === 'Yes' ? 'Unstaked' : 'UnStake'}
                         </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default History;
