import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { ethers } from "ethers";
import { useAddress, useContract, useContractRead, useContractWrite } from "@thirdweb-dev/react";
import { CONTRACT_ADDRESS } from "../../const/addresses";

const Pool = () => {
    const shortenAddress = (address) => {
        if (address && address.length > 10) {
            const start = address.slice(0, 5);
            const end = address.slice(-5);
            return `${start}..${end}`;
        }
        return address;
    };
    const navigate = useNavigate();
    const address = useAddress();
    const [myPool, setMyPool] = useState(0);
    const[poolReward, setPoolReward] = useState(0);
    const { contract } = useContract(CONTRACT_ADDRESS);
    const [poolCompleted, setPoolCompleted] = useState(false);
    const [poolClaimed, setPoolClaimed] = useState(false);
    // console.log(poolCompleted, poolClaimed);
    const { data, isLoading } = useContractRead(contract, "userInfo", [address]);
    
    useEffect(() => {
        if (data) {
            const currentPool = parseInt(data.currentPool);
            setMyPool(currentPool);
        }
    }, [data, isLoading]);

    
    const { data: poolInfo, isLoading: isPoolInfoLoading } = useContractRead(contract, "getPoolInfo", [address, myPool - 1]);
    
    const {data: poolRewards, isLoading: isPoolRewardsLoading} = useContractRead(contract, "poolRewards", [myPool - 1]);
    useEffect(() => {
        if (!isPoolRewardsLoading && poolRewards) {
            const poolReward = ethers.utils.formatUnits(poolRewards, 6);
            setPoolReward(poolReward);
        }
    }, [isPoolRewardsLoading, poolRewards]);
    
    const { mutateAsync: claimPoolReward, isLoading: isClaiming } = useContractWrite(contract, "claimPoolReward");
    
    useEffect(() => {
        if (!isPoolInfoLoading && poolInfo) {
            const poolCompleted = poolInfo[3];
            setPoolCompleted(poolCompleted);
            const poolClaimed = poolInfo[4];
            setPoolClaimed(poolClaimed);
        }
    }, [isPoolInfoLoading, poolInfo]);

    if (isLoading || isPoolInfoLoading || !poolInfo) {
        return <p>Loading...</p>;
    }

    

    // Function to format BigNumber to readable date
    const formatStartTime = (startTime) => {
        return new Date(ethers.BigNumber.from(startTime).toNumber() * 1000).toLocaleDateString("en-IN");
    };

    // Prepare matrix users for display
    const poolUsers = poolInfo[2].map((userAddress) => shortenAddress(userAddress));
    // console.log(poolUsers);
    // Fill the remaining spots with placeholders if less than 4 users
    while (poolUsers.length < 2) {
        poolUsers.push('---');
    }

    
    const handleClaimPoolReward = async () => {
        try {
            const { receipt } = await claimPoolReward(address);
            const transactionHash = receipt.transactionHash;
            toast.success(`Pool reward claimed: Transaction Hash: ${transactionHash}`);
            navigate("/dashboard");
        } catch (error) {
            toast.error(`Claiming pool reward failed: You are not eligible to claim rewards.`);
        }
    }
    
    return (
        <section className="leaderboard-area">
            <div className="container">
                <div className="col-12 col-xl-6 col-lg-6 item">
                    <div className="card tier-card prev-project-card">
                        <div className="project-content d-flex flex-column align-items-center justify-content-center">
                        <div className='d-flex justify-content-center mt-4'>    
                                  <img src={"/img/video1.gif"} style={{width: '300px', filter: 'brightness(105%)'}} alt="polygon" />
                              </div> 
                            <div className="content">
                                <h6 className="mt-3 mb-0">Current Pool: {myPool}</h6>
                                <h6 className="mt-3 mb-0">Pool Reward: {poolReward} USDT</h6>
                            </div>
                        </div>
                    </div>
                </div>
                {isPoolInfoLoading ? (
                    <p>Loading Pool info...</p>
                ) : (
                    <>
                    <div className="table-responsive">
                        <table className="table token-content table-borderless">
                            <thead>
                                <tr>
                                    <th scope="col">Referrer</th>
                                    <th scope="col">Start Time</th>
                                    <th scope="col">Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{shortenAddress(poolInfo[0])}</td>
                                    <td>{formatStartTime(poolInfo[1])}</td>
                                    <td>{poolInfo[3] ? 'Completed' : 'Running'}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="table-responsive">
                        <table className="table token-content table-borderless">
                            <thead>
                                <tr>
                                <th scope="col">First User</th>
                                    <th scope="col">Second User</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {poolUsers.map((user, index) => (
                                        <td key={index}>{user}</td>
                                    ))}
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    </>
                )}
                    <div className="project-footer d-flex align-items-center justify-content-center mt-4 mt-md-5">
                        <button
                            className="btn input-btn btn-smaller mt-2 mt-md-0 ml-md-7 active btn-primary"
                            onClick={handleClaimPoolReward}
                            disabled={isClaiming || poolClaimed || !poolCompleted}
                        >
                            {poolClaimed ? 'Already Claimed' : 'Claim Pool Reward'}
                        </button>
                    </div>
            </div>
        </section>
    );
};

export default Pool;