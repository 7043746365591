import React, { useEffect, useState, useCallback } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAddress, useContract, useContractRead, useContractWrite } from "@thirdweb-dev/react";
import { CONTRACT_ADDRESS, DEFAULT_REFERRER, ZERO_ADDRESS } from "../../const/addresses";

const Register = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const address = useAddress();
    const { contract, isLoading } = useContract(CONTRACT_ADDRESS);
    const [showModal, setShowModal] = useState(false);
    const [isUserRegistered, setIsUserRegistered] = useState(false);
    const [referrer, setReferrer] = useState(DEFAULT_REFERRER);
    const [isReferrerActive, setIsReferrerActive] = useState(false);
    
    const { data: userInfo } = useContractRead(contract, "userInfo", [address]);
    const { data: referrerData } = useContractRead(contract, "userInfo", [referrer]);

    useEffect(() => {
        const registered = userInfo?.referrer != ZERO_ADDRESS;
        setIsUserRegistered(registered);
        setShowModal(!registered);

        const searchParams = new URLSearchParams(window.location.search);
        const ref = searchParams.get("referrer") || DEFAULT_REFERRER;
        setReferrer(ref);
        setIsReferrerActive(referrerData && referrerData.totalStake != 0 || ref == DEFAULT_REFERRER);
        }, [userInfo, referrerData, location.search]);

    
    useEffect(() => {
        if (!showModal && !isUserRegistered) {
            const timer = setTimeout(() => {
                setShowModal(true);
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [showModal, isUserRegistered]);

    const handleDismissModal = () => setShowModal(false);

    const shortenAddress = useCallback((addr) => addr ? `${addr.slice(0, 6)}...${addr.slice(-6)}` : '', []);
    const { mutateAsync: register, isLoading: isRegistering } = useContractWrite(contract, "register")
    const handleRegister = async () => {
        try {
            const { receipt } = await register({ args: [referrer] });
            const transactionHash = receipt.transactionHash;
            toast.success(`Registration successful: Transaction Hash: ${transactionHash}`);
            navigate("/stake");
            navigate("/stake");
        } catch (error) {
            toast.error(`Registration failed: ${error.reason}`);
        }
    }

    return (
        <div id="search" className={`modal fade ${showModal ? 'show' : ''}`} style={{ display: showModal ? 'block' : 'none' }}>
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content rounded">
                    <div className="modal-header">
                        Register <i className="far fa-times-circle icon-close" onClick={handleDismissModal}
                        disabled={isRegistering}
                        />
                    </div>
                    <div className="modal-body">
                    <div className="card-body">
                        <h5>Welcome OnBoard!</h5>
                        <p>Here is your Inviter Address: {shortenAddress(referrer)}</p>
                        <p style={{ color: isReferrerActive ? 'green' : 'red' }}>
                            {referrer === DEFAULT_REFERRER ? "You are registering under Default Referrer. You can Register using the referrer." : isReferrerActive ? "You can Register using the referrer." : "Referrer is Inactive! You can't Register"}
                        </p>
                    </div>
                </div>
                    <div className="modal-footer d-flex align-items-center mt-4 mt-md-5">
                        <button type="button" className="btn btn-bordered-white rounded btn-small active btn-outline-white mr-4" onClick={handleDismissModal}>Cancel</button>
                        <button type="button" className="btn btn-bordered-white rounded btn-small active btn-outline-success mr-4" onClick={handleRegister}
                        disabled={isRegistering || !isReferrerActive}
                        >
                        Confirm
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Register;