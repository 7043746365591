import React, { Component } from 'react';

import Header from '../components/Header/Header';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import Splinter from '../components/Splinter/Splinter';
import Cta from '../components/Cta/Cta';
import Footer from '../components/Footer/Footer';
import ModalSearch from '../components/Modal/ModalSearch';
import ModalMenu from '../components/Modal/ModalMenu';

class SplinterPage extends Component {
    render() {
        return (
            <div className="main">
                <Header />
                <Breadcrumb title="Splinter" subpage="Pages" page="Splinter" />
                <Splinter/>
                <Cta />
                <Footer />
                <ModalSearch />
                <ModalMenu />
            </div>
        );
    }
}

export default SplinterPage;