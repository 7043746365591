import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { useNavigate } from 'react-router-dom';
import { ethers } from "ethers";
import { useAddress, useBalance, useContract, useContractRead, useContractWrite } from "@thirdweb-dev/react";
import { CONTRACT_ADDRESS, TOKEN_ADDRESS, ORACLE_ADDRESS, USDC_ADDRESS } from "../../const/addresses";

const Game = () => {

    const shortenAddress = (address) => {
        if (address && address.length > 8) {
            const start = address.slice(0, 4);
            const end = address.slice(-4);
            return `${start}...${end}`;
        }
        return address;
    }

    const address = useAddress();
    const { contract } = useContract(CONTRACT_ADDRESS);
    const { contract: tokenContract} = useContract(TOKEN_ADDRESS);
    const [gameRound, setGameRound] = useState(0);
    const [startTime, setStartTime] = useState(0);
    const [endTime, setEndTime] = useState(0);
    const [roundEndTime, setRoundEndTime] = useState(0);
    const [countdown, setCountdown] = useState("");

    const {data: rewardInfo, isLoading: isLoadingRewardInfo} = useContractRead(contract, "rewardInfo", [address]);
    const [availableBalance, setAvailableBalance] = useState(0);
    useEffect(() => {
      if (!isLoadingRewardInfo && rewardInfo) {
          const availableBalance = ethers.utils.formatUnits(rewardInfo.availableBalance, 6);
          setAvailableBalance(availableBalance);
      }
    }, [isLoadingRewardInfo, rewardInfo]);


    const { data: nativeBalance, isLoading: isNativeBalanceLoading } = useBalance();

    const { data: tokenBalance, isLoading: isTokenBalanceLoading } = useBalance(TOKEN_ADDRESS);

    const { contract: oracleContract} = useContract(ORACLE_ADDRESS);

    const { mutateAsync: estimateAmountOut} = useContractWrite(oracleContract, "estimateAmountOut");

    const[ usdcValue, setUsdcValue] = useState(0);

    const [isEstimating, setIsEstimating] = useState(false);

    const attemptEstimateAmountOut = useCallback(async (retries = 3, delay = 3000) => {
        setIsEstimating(true);
        let lastError= null;

        for (let i = 0; i < retries; i++) {
            try {
                const tokenAmount = tokenBalance.value;
                const amountString = tokenAmount.toString();
                const weiValue = ethers.utils.parseUnits(amountString, 'wei');
                const data = await estimateAmountOut({ args: [TOKEN_ADDRESS, weiValue, 5]});
                const stringValue = ethers.BigNumber.from(data);
                const numberValue = ethers.utils.formatUnits(stringValue, 6);
                const amountUsdc = parseFloat(numberValue).toFixed(3);
                setUsdcValue(amountUsdc);
                setIsEstimating(false);
                return;
            } catch (error) {
                lastError = error;
                await new Promise((resolve) => setTimeout(resolve, delay));
            }
        }
        setIsEstimating(false);
        console.error("Failed to estimate amount out:", lastError);
    }, [tokenBalance, estimateAmountOut]);

    useEffect(() => {
        if(!isTokenBalanceLoading && tokenBalance) {
            attemptEstimateAmountOut();
        }
    }, [isTokenBalanceLoading, tokenBalance, attemptEstimateAmountOut]);

    const [nativeValue, setNativeValue] = useState(0);
    const [tokenValue, setTokenValue] = useState(0);

    useEffect(() => {
        if(!isTokenBalanceLoading && tokenBalance) {
            const myTokenBalance = tokenBalance.displayValue;
            const myPLGBalance = parseFloat(myTokenBalance).toFixed(3);
            setTokenValue(myPLGBalance);
        }
    }, [isTokenBalanceLoading, tokenBalance]);

    useEffect(() => {
        if(!isNativeBalanceLoading && nativeBalance) {
            const myNativeBalance = nativeBalance.displayValue;
            const myMatic = parseFloat(myNativeBalance).toFixed(3);
            setNativeValue(myMatic);
        }
    }, [isNativeBalanceLoading,nativeBalance]);


    const [bettingStatus, setBettingStatus] = useState("Game Started"); // New state for betting status
    
    const { data: gameRoundData, isLoading: gameRoundDataLoading, refetch } = useContractRead(contract, "getCurrentGameRoundDetails");
    
    useEffect(() => {
        const interval = setInterval(() => {
            refetch(); // Manually trigger a refetch
        }, 5000); // Refetch every 10 seconds
    
        return () => clearInterval(interval);
    }, [refetch]);

    useEffect(() => {
        if(!gameRoundDataLoading && gameRoundData) {
            const currentRound = parseInt(gameRoundData.roundCount);
            setGameRound(currentRound);
            const startTime = new Date(gameRoundData.startTime * 1000).toLocaleTimeString();
            const endTime = new Date(gameRoundData.endTime * 1000).toLocaleTimeString();
            setStartTime(startTime);
            setEndTime(endTime);
            const roundEndTime = new Date(gameRoundData.endTime * 1000);
            setRoundEndTime(roundEndTime.getTime());
        }
    }, [gameRoundDataLoading, gameRoundData]);

    useEffect(() => {
        const updateCountdown = () => {
            const now = new Date().getTime(); // Get current time as timestamp
            const difference = Math.floor((roundEndTime - now) / 1000); // difference in seconds

            if (difference > 0) {
                setCountdown(`${difference} seconds`);
                if (difference < 60) {
                    setBettingStatus("Game Paused 1 Min. For Result"); // Update betting status
                    setCountdown(prev => `<span class="text-danger">${prev}</span>`);
                } else {
                    setBettingStatus("Game Started"); // Ensure status is reset if more than 60 seconds left
                    setCountdown(prev => `<span class="text-success">${prev}</span>`);
                }
            } else {
                setCountdown("Time's up!");
                setBettingStatus("Game Paused 1 Min. For Result"); // Ensure betting is paused when time is up
            }
        };

        updateCountdown(); // Update immediately on effect run
        const interval = setInterval(updateCountdown, 1000);

        return () => clearInterval(interval);
    }, [roundEndTime]);

    const {data: roundPlayers, isLoading: roundPlayersLoading} = useContractRead(contract, "getCurrentRoundPlayers");
    const [playersData, setPlayersData] = useState([]);

    useEffect(() => {
        if(!roundPlayersLoading && roundPlayers) {
            const formattedPlayers = roundPlayers.roundPlayersArray.map((player, index) => ({
                address: player,
                amountBet: ethers.utils.formatUnits(roundPlayers.amountBetsArray[index], 6),
                color: roundPlayers.colorArray[index]
                
            }));
            setPlayersData(formattedPlayers);
        }
    }, [roundPlayersLoading, roundPlayers]);

    const roundsToFetch = 288;
    const winners = [];
    
    
    const FetchRoundWinners = (round) => {
        const { data: roundWinnersData } = useContractRead(contract, "getRoundWinners", [round]);
        if (roundWinnersData) {
            const roundWinners = roundWinnersData[0].map((winner, index) => ({
                address: winner,
                amountWon: ethers.utils.formatUnits(roundWinnersData[1][index], 6),
                round: round // Include the round number in the data
            }));
            return roundWinners;
      
        }
        return [];
        
    };

    const FetchClaimStatus = (address, round) => {
      const {data: roundWinnerBets } = useContractRead(contract, "roundWinnerBets", [address, round]);
      if (roundWinnerBets) {
          const isClaimed = roundWinnerBets.isClaimed;
          return isClaimed;
      }
    }
  
  // Inside the loop where winners are fetched
  for (let i = gameRound - 1; i >= gameRound - roundsToFetch; i--) {
    const roundWinners = FetchRoundWinners(i);
    const status = FetchClaimStatus(address, i);
    roundWinners.forEach(winner => {
        winners.push({ ...winner, isClaimed: status });
    });
  }
  

    const currentUserEntries = winners.filter(winner => winner.address === address);
    
    const {mutateAsync: claimWinnings, isLoading: isClaimWinningsLoading} = useContractWrite(contract, "claimWinnings");
      const handleClaimWinnings = async (address, round) => {
        try {
            const { receipt } = await claimWinnings({args: [address, round]});
            const transactionHash = receipt.transactionHash;
            toast.success(`Transaction Hash: ${transactionHash}`);
        } catch (error) {
            toast.error(`Transaction failed: ${error.reason}`);
        }
        // window.location.reload();
    }
  
  
   
    const {data: currentResultColor, isLoading: isCurrentResultColorLoading} = useContractRead(contract, "getRoundGameResult", [gameRound -1]);
    const [currentResult , setCurrentResult] = useState("");
    useEffect(() => {
        if(!isCurrentResultColorLoading && currentResultColor) {
            setCurrentResult(currentResultColor);
        }
    }, [isCurrentResultColorLoading, currentResultColor]);

    

    const {mutateAsync: placeBet, isLoading: isPLaceBetLoading} = useContractWrite(contract, "placeBet" );
    const[betAmount, setBetAmount] = useState("");
    
    const handlePlaceBetRed = async () => {
        try {
            const inputAmount = ethers.utils.parseUnits(betAmount, 6);
            const { receipt } = await placeBet({args: [inputAmount, 2]});
            const transactionHash = receipt.transactionHash;
            toast.success(`Transaction Hash: ${transactionHash}`);
            // window.location.reload();
        } catch (error) {
            toast.error(`Transaction failed: ${error.reason}`);
            
        }
        window.location.reload();
    };

    const handlePlaceBetGreen = async () => {
        try {
            const inputAmount = ethers.utils.parseUnits(betAmount, 6);
            const { receipt } = await placeBet({args: [inputAmount, 1]});
            const transactionHash = receipt.transactionHash;
            toast.success(`Transaction Hash: ${transactionHash}`);
            // window.location.reload();
        } catch (error) {
            toast.error(`Transaction failed: ${error.reason}`);
            
        }
        window.location.reload();
    };

    const {mutateAsync: addBalance, isLoading: isAddBalanceLoading} = useContractWrite(contract, "addAvailableBalance");
    const [usdcAmount, setUsdcAmount] = useState("");
    const handleAddBalance = async () => {
      try {
        const inputAmount = ethers.utils.parseUnits(usdcAmount, 6); // Adjust based on token's decimals
    
        // Check Allowance
        const currentAllowance = await tokenContract?.erc20.allowance(CONTRACT_ADDRESS, address);
        console.log(currentAllowance);
        const requiredAllowance = ethers.utils.parseUnits('100', 18); // Ensure this is a BigNumber with the correct decimals
        console.log(`Formatted Allowance in Wei: ${requiredAllowance.toString()}`);
    
        // Compare using BigNumber comparison methods
        if (currentAllowance.value.lt(requiredAllowance)) {
          // If current allowance is less than required allowance, set a new allowance
          await tokenContract?.erc20.setAllowance(CONTRACT_ADDRESS, 10000);
        }
    
        const { receipt } = await addBalance({ args: [inputAmount] });
        const transactionHash = receipt.transactionHash;
        toast.success(`Balance added successfully: Transaction Hash: ${transactionHash}`);
      } catch (error) {
        toast.error(`Balance addition failed: ${error.reason}`);
      }
      // window.location.reload();
    };

    const [inputError, setInputError] = useState('');

    const handleInputChange = (e) => {
        const inputAmount = e.target.value;
        setUsdcAmount(inputAmount);
        if (parseFloat(inputAmount) > parseFloat(usdcValue)) {
            setInputError('Input amount exceeds available USDC value.');
        } else {
            setInputError('');
        }
    };

    const [gasError, setGasError] = useState('');
    useEffect(() => {
        const checkGasFee = async () => {
            const requiredGasFee = ethers.utils.parseUnits('0.01', 'ether'); // Gas fee in wei for 0.01 Matic
            if (nativeBalance && nativeBalance.value.lt(requiredGasFee)) {
                setGasError("Insufficient Matic for the transaction fee. You need at least 0.01 Matic.");
            } else {
                setGasError(''); // Clear error if the condition is no longer met
            }
        };
    
        checkGasFee();
    }, [nativeBalance]);

    const getColorCircle = (result) => {
        switch(result) {
            case 1:
                return <div className="color-circle green"></div>;
            case 2:
                return <div className="color-circle red"></div>;
            case 3:
                return <div className="color-circle yellow"></div>;
            default:
                return <span>locked</span>;
        }
    };

    const [amountOut, setAmountOut] = useState('');
    const handleAmountOut = useCallback(async () => {
      try {
          const amountIn = ethers.utils.parseUnits(usdcAmount, 6);
          const outPut = await estimateAmountOut({ args: [USDC_ADDRESS, amountIn, 5]});
          const plgAmount = ethers.utils.formatUnits(outPut, 18);
          const amountOut = parseFloat(plgAmount).toFixed(2);
          
          if (usdcAmount !== '') {
              setAmountOut(amountOut);
          } else {
              setAmountOut('');
          }
      } catch (error) {
          console.error("Failed to estimate amount out:", error);
      }
    }, [usdcAmount, estimateAmountOut]);

    useEffect(() => {
      if(usdcAmount !== '') {
        handleAmountOut();
      }
    }, [usdcAmount, handleAmountOut]);

    return (
      <section className="staking-area">
        <div className="row justify-content-center">
          <div className="col-12 col-md-12">
            {/* <div className="row items"> */}
            {/* <div className="col-12 col-md-6 col-lg-12 item"> */}
            {/* Single Tier Card */}
            <div className="card no-hover tier-card text-center">
              <div className="ribbon ribbon-top-left">
                <span>win 2x of bet</span>
              </div>
              <div className="d-flex justify-content-center mt-4">
                <img
                  src={"/img/game.gif"}
                  style={{ width: "225px", filter: "brightness(85%)" }}
                  alt="polygon"
                />
              </div>
              <h4 className="title mb-0 text-success">
                Play <span className="text-danger">Color</span> Game
              </h4>
              <span className="category mt-2">
                Get bet amount <span className="text-success">double</span> as
                winning
              </span>
              <div className="card-body">
                <ul className="list-unstyled">
                  <li>
                    <span>Current Round</span>
                    <span>{gameRound}</span>
                  </li>
                  <li>
                    <span>Round Start Time</span>
                    <span className="text-success">{startTime}</span>
                  </li>
                  <li>
                    <span>Current Round End Time</span>
                    <span className="text-danger">{endTime}</span>
                  </li>
                  <li>
                    <span>Last Round Result</span>
                    <span>{getColorCircle(currentResult)}</span>
                  </li>
                  <li>
                    <span>Next Result In:</span>
                    <span
                      className="countdown"
                      dangerouslySetInnerHTML={{ __html: countdown }}
                    ></span>
                  </li>
                  <li>
                    <span>Game Status</span>
                    <span
                      className={`betting-status ${
                        bettingStatus === "Game Started"
                          ? "text-success"
                          : "text-danger"
                      }`}
                    >
                      {bettingStatus}
                    </span>
                  </li>
                </ul>
              </div>
              <div className="card-body">
                <div className="row">
                  {/* Single Staking Item */}
                  <div className="col-12 col-md-12  justify-content-center">
                    <div className="input-area d-flex flex-column">
                    <h4 className="item-title mb-2">Place Bet</h4>
                      <div className="input-text ">
                        <input
                          type="text"
                          value={betAmount}
                          onChange={(e) => setBetAmount(e.target.value)}
                          placeholder={"Input Bet Amount"}
                        />
                        <div className="predefined-bets mt-2 d-flex justify-content-between ">
                          {[0.1, 0.2, 0.5, 1.0, 10, 100].map((amount) => (
                            <button
                              className="bet-button btn btn-bordered-pill-green"
                              key={amount}
                              onClick={() => setBetAmount(amount.toString())}
                            >
                              {amount}
                            </button>
                          ))}
                        </div>
                      </div>
                      <div className="button-group d-flex justify-content-around mt-4 mt-md-5">
                        <button
                          className="btn btn-bordered-bet-red"
                          onClick={handlePlaceBetRed}
                          disabled={
                            isPLaceBetLoading ||
                            bettingStatus === "Game Paused 1 Min. For Result"
                          }
                        >
                          Predict Red
                        </button>
                        <button
                          className="btn btn-bordered-bet-green"
                          onClick={handlePlaceBetGreen}
                          disabled={
                            isPLaceBetLoading ||
                            bettingStatus === "Game Paused 1 Min. For Result"
                          }
                        >
                          Predict Green
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* </div> */}
            {/* </div> */}

            <div className=" col-12 col-md-6 col-lg-12 card tier-card prev-project-card mt-4">
              <h6 className="m-0 mb-3">My Wallet Balances</h6>
              <div className="card-body">
                <div className="project-content d-flex justify-content-around align-items-center">
                  <div className="content">
                    <h6 className="m-0">{tokenValue}</h6>
                    <h6 className="mt-1 mb-0">$PLG</h6>
                  </div>
                  <span className="text-lg">≈</span>
                  <div className="content">
                    <h6 className="m-0"> {usdcValue}</h6>
                    <h6 className="mt-1 mb-0">USDT</h6>
                  </div>
                  <div className="content">
                    <h6 className="m-0">{nativeValue}</h6>
                    <h6 className="mt-1 mb-0">Matic</h6>
                  </div>
                </div>
              </div>
            </div>
            <div className="card tier-card prev-project-card mt-4">
              <div className="project-content d-md-flex flex-column flex-md-row align-items-center justify-content-md-between">
                <div className="item-header d-flex align-items-center mb-md-0">
                  <img
                    className="card-img-top avatar-max-lg"
                    src={"/img/icons/wallet.png"}
                    alt=""
                  />
                  <div className="content ml-4">
                    <h4 className="m-0">$ {availableBalance}</h4>
                    <h6 className="mt-3 mb-0">My Available Balance</h6>
                  </div>
                </div>
                <div className="input-box my-4">
                  <div className="input-area d-flex flex-column flex-md-row">
                    <div className="input-text">
                      <input
                        type="text"
                        max={usdcValue}
                        placeholder={"Input Amount"}
                        onChange={handleInputChange}
                        readOnly={isEstimating}
                      />
                      <a>USDT</a>
                    </div>

                    <p className="text-center">
                    ≈ <span>{amountOut} $PLG</span>
                  </p>

                    <button
                      className="btn input-btn mt-2 mt-md-0 ml-md-3 active btn-primary"
                      onClick={handleAddBalance}
                      disabled={
                        !!gasError || !!inputError || isAddBalanceLoading
                      }
                    >
                      {" "}
                      AddBalance
                    </button>
                  </div>
                </div>
                {inputError && <p className="text-warning">*{inputError}*</p>}
                {gasError && <p className="text-danger">*{gasError}*</p>}
              </div>
            </div>

            {playersData.length > 0 ? (
              <div className="leaderboard-area col-12 col-md-6 col-lg-12 ">
                <h4 className="title mb-0 ">Round Players</h4>
                <div className="table-responsive">
                  <table className="table token-content table-borderless responsive-table">
                    <thead>
                      <tr>
                        <th scope="col" style={{ width: "5%" }}>
                          SR.
                        </th>
                        <th scope="col" style={{ width: "8%" }}>
                          Address
                        </th>
                        <th scope="col" style={{ width: "12%" }}>
                          Amount
                        </th>
                        <th scope="col" style={{ width: "9%" }}>
                          Color
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {playersData.map((item, index) => (
                        <tr key={index}>
                          <td style={{ width: "5%" }}>{index + 1}</td>
                          <td style={{ width: "8%" }}>
                            {shortenAddress(item.address)}
                          </td>
                          <td style={{ width: "12%" }}>{item.amountBet}</td>
                          <td style={{ width: "9%" }}>
                            {getColorCircle(item.color)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div className="leaderboard-area col-12 col-md-6 col-lg-12 ">
                <h4 className="title mb-0 ">Round Players</h4>
                <p>NO DATA</p>
              </div>
            )}

            {currentUserEntries.length > 0 ? (
              <div className="leaderboard-area col-12 col-md-6 col-lg-12 ">
                <h4 className="title mb-0 ">My Winnings</h4>
                <div className="table-responsive">
                  {/* <table className="table token-content table-borderless responsive-table"> */}
                  <table className="table token-content table-borderless responsive-table">
                    <thead>
                      <tr>
                        <th scope="col" style={{ width: "5%" }}>
                          SR.
                        </th>
                        <th scope="col" style={{ width: "8%" }}>
                          Address
                        </th>
                        <th scope="col" style={{ width: "12%" }}>
                          Amount
                        </th>
                        <th scope="col" style={{ width: "10%" }}>
                          Round#
                        </th>
                        <th scope="col" style={{ width: "10%" }}>
                          Claim
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentUserEntries.map((entry, index) => (
                        <tr key={index}>
                          <td style={{ width: "5%" }}>{index + 1}</td>
                          <td style={{ width: "8%" }}>
                            {shortenAddress(entry.address)}
                          </td>
                          <td style={{ width: "12%" }}>{entry.amountWon}</td>
                          <td style={{ width: "10%" }}>{entry.round}</td>
                          <td style={{ width: "10%" }}>
                            <button
                              className="btn btn-bordered-green btn-smaller"
                              onClick={() =>
                                handleClaimWinnings(address, entry.round)
                              }
                              disabled={
                                isClaimWinningsLoading || entry.isClaimed
                              } // Disable button if there's a gas error or input error
                            >
                              {entry.isClaimed ? "Claimed" : "Claim"}
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div className="leaderboard-area col-12 col-md-6 col-lg-12 ">
                <h4 className="title mb-0 ">My Winnings</h4>
                <p>NO DATA</p>
              </div>
            )}

            {winners.length > 0 ? (
              <div className="leaderboard-area col-12 col-md-6 col-lg-12">
                <h4 className="title mb-0 ">Last Round Winners</h4>
                <div className="table-responsive">
                  {/* <table className="table token-content table-borderless responsive-table"> */}
                  <table className="table token-content table-borderless responsive-table">
                    <thead>
                      <tr>
                        <th scope="col" style={{ width: "5%" }}>
                          SR.
                        </th>
                        <th scope="col" style={{ width: "8%" }}>
                          Address
                        </th>
                        <th scope="col" style={{ width: "12%" }}>
                          Amount
                        </th>
                        <th scope="col" style={{ width: "10%" }}>
                          Round#
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {winners.map((item, index) => (
                        <tr key={index}>
                          <td style={{ width: "5%" }}>{index + 1}</td>
                          <td style={{ width: "8%" }}>
                            {shortenAddress(item.address)}
                          </td>
                          <td style={{ width: "12%" }}>{item.amountWon}</td>
                          <td style={{ width: "10%" }}>{item.round}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div className="leaderboard-area col-12 col-md-6 col-lg-12 ">
                <h4 className="title mb-0 ">Last Round Winners</h4>
                <p>NO DATA</p>
              </div>
            )}
          </div>
        </div>
      </section>
    );
}

export default Game;