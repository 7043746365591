import React, { Component } from 'react';
import Header from '../components/Header/Header';
import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import Game from '../components/Game/Game';
import Cta from '../components/Cta/Cta';
import Footer from '../components/Footer/Footer';
import ModalMenu from '../components/Modal/ModalMenu';
class GamePage extends Component {
    render() {
        return (
            <div className="main">
                <Header />
                <Breadcrumb title="Game" subpage="Pages" page="Game" />
                <Game />
                <Cta />
                <Footer />
                <ModalMenu />
            </div>
        );
    }
}

export default GamePage;