import { useAddress, useContract, useContractRead } from "@thirdweb-dev/react";
import { ethers } from 'ethers';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CONTRACT_ADDRESS } from "../../const/addresses";
import React, { useState, useEffect } from 'react';


const Referrals = () => {
    const shortenAddress = (address) => {
        if (address && address.length > 6) {
            const start = address.slice(0, 3);
            const end = address.slice(-3);
            return `${start}...${end}`;
        }
        return address;
    }

    const address = useAddress();
    const { contract } = useContract(CONTRACT_ADDRESS);
    const { data, isLoading } = useContractRead(contract, "userInfo", [address]);
    

    const { data: level1count, isLoading: isLoadingLevel1Count } = useContractRead(contract, "getLevel1Team", [address]);
    const formattedLevel1Count = level1count? level1count.toString() : 0;

    const [ level1Team, setLevel1Team ] = useState(0);

    useEffect(() => {
      setLevel1Team(formattedLevel1Count);
    }, [isLoadingLevel1Count ,formattedLevel1Count]);

    const { data: level2count, isLoading: isLoadingLevel2Count } = useContractRead(contract, "getLevel2Team", [address]);
    const formattedLevel2Count = level2count? level2count.toString() : 0;

    const [ level2Team, setLevel2Team ] = useState(0);
    useEffect(() => {
      setLevel2Team(formattedLevel2Count);
    }, [isLoadingLevel2Count,formattedLevel2Count]);

    const { data: level3Count, isLoading: isLoadingLevel3Count } = useContractRead(contract, "getLevel3Team", [address]);
    const formattedLevel3Count = level3Count? level3Count.toString() : 0;

    const [ level3Team, setLevel3Team ] = useState(0);
    useEffect(() => {
      setLevel3Team(formattedLevel3Count);
    }, [isLoadingLevel3Count,formattedLevel3Count]);

    const { data: level4Count, isLoading: isLoadingLevel4Count } = useContractRead(contract, "getLevel4Team", [address]);
    const formattedLevel4Count = level4Count? level4Count.toString() : 0;
    const [ level4Team, setLevel4Team ] = useState(0);
    useEffect(() => {
      setLevel4Team(formattedLevel4Count);
    }, [isLoadingLevel4Count,formattedLevel4Count]);

    const { data: level5Count, isLoading: isLoadingLevel5Count } = useContractRead(contract, "getLevel5Team", [address]);
    const formattedLevel5Count = level5Count? level5Count.toString() : 0;
    const [ level5Team, setLevel5Team ] = useState(0);
    useEffect(() => {
      setLevel5Team(formattedLevel5Count);
    }, [isLoadingLevel5Count,formattedLevel5Count]);

    const { data: level6Count, isLoading: isLoadingLevel6Count } = useContractRead(contract, "getLevel6Team", [address]);
    const formattedLevel6Count = level6Count? level6Count.toString() : 0;
    const [ level6Team, setLevel6Team ] = useState(0);
    useEffect(() => {
      setLevel6Team(formattedLevel6Count);
    }, [isLoadingLevel6Count,formattedLevel6Count]);

    const { data: totalTeamCount, isLoading: isLoadingTotalTeamCount } = useContractRead(contract, "getTotalTeam" , [address]);
    const formattedTotalTeamCount = totalTeamCount? totalTeamCount.toString() : 0;
    // console.log(formattedTotalTeamCount);

    const [TeamCount, setTeamCount] = useState(0);
    useEffect(() => {
        setTeamCount(formattedTotalTeamCount);
    }, [isLoadingTotalTeamCount, formattedTotalTeamCount]);

    
    const { data: getReferralStake, isLoading: isLoadingReferralsStake } = useContractRead(contract, "getReferralStake", [address]);
    const formattedReferralStake = getReferralStake? getReferralStake.toString() / 1000000 : 0;
    

    const [referralCount, setReferralCount] = useState(0);

    
    const [referralStake, setReferralStake] = useState(0);

    useEffect(() => {
        setReferralStake(formattedReferralStake);
    }, [isLoadingReferralsStake, formattedReferralStake]);

    const [myMatrix, setMyMatrix] = useState(0);
    const [myDirectTeam, setMyDirectTeam] = useState(0);
    const [myTotalTeam, setMyTotalTeam] = useState(0);
    const [myLevel, setMyLevel] = useState(0);
    const [myRef, setMyRef] = useState(null);
    const [myAmbRewards, setMyAmbRewards] = useState(0);
    const [myAvlBal, setMyAvlBal] = useState(0);
    const [myLevRewards, setMyLevRewards] = useState(0);
    const [myMasRewards, setMyMasRewards] = useState(0);
    const [myMatRewards, setMyMatRewards] = useState(0);
    const [myTopRewards, setMyTopRewards] = useState(0);
    const [myTotRewards, setMyTotRewards] = useState(0);
    const [myWithDrawnBal, setMyWithDrawnBal] = useState(0);
    const [myStake, setMyStake] = useState(0);
    const [referralLink, setReferralLink] = useState('');

    useEffect(() => {
      if (address) {
        const newReferralLink = `${window.location.origin}/?referrer=${address}`;
        setReferralLink(newReferralLink);
      }
    }, [address]);

    const copyToClipboard = async () => {
      try {
        await navigator.clipboard.writeText(referralLink);
        toast.success('Referral link copied to clipboard!');
      } catch (err) {
        console.error('Failed to copy:', err);
        toast.error('Failed to copy link.');
      }
    };

    useEffect(() => {
        if (data) {
            const currentMatrix = parseInt(data.currentMatrix);
            setMyMatrix(currentMatrix);
            const myRef = data.referrer;
            setMyRef(myRef);
            const myStake = parseInt(data.totalStake) / 1000000;
            setMyStake(myStake);
            const myDirectTeam = parseInt(data.totalReferrals);
            setMyDirectTeam(myDirectTeam);
            const myTotalTeam = parseInt(data.teamCount);
            setMyTotalTeam(myTotalTeam);
        }
    }, [isLoading, data]);

    
    const { data: levelData, isLoading: isLevelDataLoading } = useContractRead(contract, "getUserLevel", [address]);
    useEffect(() => {
        if (levelData) {
            const myLevel = parseInt(levelData);
            setMyLevel(myLevel);
        }
    }, [isLevelDataLoading, levelData]);
    

    const { data: levelUserInfo, isLoading: isLoadingLevelUserInfo } = useContractRead(contract, "getLevelUsersInfo", [address]);
    const [level1Info, setLevel1Info] = useState(["No Data"]);
    const [level2Info, setLevel2Info] = useState(["No Data"]);
    const [level3Info, setLevel3Info] = useState(["No Data"]);
    const [level4Info, setLevel4Info] = useState(["No Data"]);
    const [level5Info, setLevel5Info] = useState(["No Data"]);
    const [level6Info, setLevel6Info] = useState(["No Data"]);
    const [totalTeamStake, setTotalTeamStake] = useState(["No Data"]);
    useEffect(() => {
      if (!isLoadingLevelUserInfo && levelUserInfo) {
          const levelInfoArray = levelUserInfo.map(level => {
              return level.map(user => {
                  return {
                      address: user[0],
                      referrer: user[1],
                      startTime: new Date(user[2].toNumber() * 1000).toLocaleString("en-IN"),
                      totalStake: ethers.utils.formatUnits(user[3], 6),
                  };
              });
          });
  
          setLevel1Info(levelInfoArray[0]);
          setLevel2Info(levelInfoArray[1]);
          setLevel3Info(levelInfoArray[2]);
          setLevel4Info(levelInfoArray[3]);
          setLevel5Info(levelInfoArray[4]);
          setLevel6Info(levelInfoArray[5]);
      }
  }, [isLoadingLevelUserInfo, levelUserInfo]);

  useEffect(() => {
      if (!isLoadingLevelUserInfo && levelUserInfo) {
          const totalTeamStake = levelUserInfo.reduce((total, level) => {
              return total + level.reduce((levelTotal, user) => {
                  if (user[0] !== address) {
                      return levelTotal + parseInt(ethers.utils.formatUnits(user[3], 6));
                  } else {
                      return levelTotal;
                  }
              }, 0);
          }, 0);

          setTotalTeamStake(totalTeamStake);
      }
  }, [isLoadingLevelUserInfo, levelUserInfo, address]);
    
    
    




    return (
      <section className="staking-area">
        <div id="gameon-accordion" className="container accordion">
          <div className="row justify-content-center leaderboard-area">
            <div className="col-12 col-xl-6 col-lg-6 item ">
              <div className="card tier-card prev-project-card">
                <h4 className="m-0 mb-2">My Referral Link</h4>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    value={referralLink}
                    disabled
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-outline-secondary btn-smaller rounded-right"
                      type="button"
                      onClick={copyToClipboard}
                    >
                      Copy
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-6 col-lg-6 item mt-2">
              <div className="card tier-card prev-project-card">
                <div className="project-content d-md-flex flex-column flex-md-row align-items-center justify-content-md-between">
                  <div className="item-header d-flex align-items-center mb-md-0">
                    <img
                      className="card-img-top avatar-max-lg"
                      src={"/img/icons/level.png"}
                      alt=""
                    />
                    <div className="content ml-4">
                      <h4 className="m-0">{myLevel}</h4>
                      <h6 className="mt-3 mb-0">My Level</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-6 col-lg-6 item mt-2">
              <div className="card tier-card prev-project-card">
                <div className="project-content d-md-flex flex-column flex-md-row align-items-center justify-content-md-between">
                  <div className="item-header d-flex align-items-center mb-md-0">
                    <img
                      className="card-img-top avatar-max-lg"
                      src={"/img/icons/teamdirect.svg"}
                      alt=""
                    />
                    <div className="content ml-4">
                      <h4 className="m-0">{level1Team}</h4>
                      <h6 className="mt-3 mb-0">My Direct Referrals</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-6 col-lg-6 item mt-2">
              <div className="card tier-card prev-project-card">
                <div className="project-content d-md-flex flex-column flex-md-row align-items-center justify-content-md-between">
                  <div className="item-header d-flex align-items-center mb-md-0">
                    <img
                      className="card-img-top avatar-max-lg"
                      src={"/img/icons/team2.svg"}
                      alt=""
                    />
                    <div className="content ml-4">
                      <h4 className="m-0">{TeamCount}</h4>
                      <h6 className="mt-3 mb-0">My Total Team</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-6 col-lg-6 item mt-2">
              <div className="card tier-card prev-project-card">
                <div className="project-content d-md-flex flex-column flex-md-row align-items-center justify-content-md-between">
                  <div className="item-header d-flex align-items-center mb-md-0">
                    <img
                      className="card-img-top avatar-max-lg"
                      src={"/img/icons/money.svg"}
                      alt=""
                    />
                    <div className="content ml-4">
                      <h4 className="m-0">${referralStake}</h4>
                      <h6 className="mt-3 mb-0">My Direct Team Stake</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-6 col-lg-6 item mt-2">
              <div className="card tier-card prev-project-card">
                <div className="project-content d-md-flex flex-column flex-md-row align-items-center justify-content-md-between">
                  <div className="item-header d-flex align-items-center mb-md-0">
                    <img
                      className="card-img-top avatar-max-lg"
                      src={"/img/icons/team-stake.png"}
                      alt=""
                    />
                    <div className="content ml-4">
                      <h4 className="m-0">${totalTeamStake}</h4>
                      <h6 className="mt-3 mb-0">My Total Team Stake</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-10">
              <div className="tier-card single-accordion-item mt-2">
                {/* Card Header */}
                <div className="card-header bg-inherit border-0 p-0">
                  <h2 className="m-0">
                    <button
                      className="btn staking-btn d-block text-left w-100 py-4"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseOne1"
                    >
                      <div className="row">
                        <div className="col-12 col-md-8">
                          <div className="media flex-column flex-md-row">
                            <div className="content media-body mt-4 mt-md-0 ml-md-4">
                              <h4 className="m-0">Level 1</h4>
                              <span className="d-inline-block mt-2">
                                Users: {level1Team}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </button>
                  </h2>
                </div>
                <div
                  id="collapseOne1"
                  className="collapse"
                  data-parent="#gameon-accordion"
                >
                  {/* Card Body */}
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12">
                        <div className="table-responsive">
                          <table className="table token-content table-borderless">
                            <thead>
                              <tr>
                                <th scope="col">Address</th>
                                <th scope="col">Referrer</th>
                                <th scope="col">Start</th>
                                <th scope="col">Stake</th>
                              </tr>
                            </thead>
                            <tbody>
                              {level1Info.map((user, i) => (
                                <tr key={i}>
                                  <td>{shortenAddress(user.address)}</td>
                                  <td>{shortenAddress(user.referrer)}</td>
                                  <td>{user.startTime}</td>
                                  <td>${user.totalStake}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-10 mt-2">
              <div className="single-accordion-item">
                {/* Card Header */}
                <div className="card-header bg-inherit border-0 p-0">
                  <h2 className="m-0">
                    <button
                      className="btn staking-btn d-block text-left w-100 py-4"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseOne2"
                    >
                      <div className="row">
                        <div className="col-12 col-md-8">
                          <div className="media flex-column flex-md-row">
                            <div className="content media-body mt-4 mt-md-0 ml-md-4">
                              <h4 className="m-0">Level 2</h4>
                              <span className="d-inline-block mt-2">
                                Users: {level2Team}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </button>
                  </h2>
                </div>
                <div
                  id="collapseOne2"
                  className="collapse"
                  data-parent="#gameon-accordion"
                >
                  {/* Card Body */}
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12 leaderboard-area">
                        <div className="table-responsive">
                        <table className="table token-content table-borderless">
                            <thead>
                              <tr>
                                <th scope="col">Address</th>
                                <th scope="col">Referrer</th>
                                <th scope="col">Start</th>
                                <th scope="col">Stake</th>
                              </tr>
                            </thead>
                            <tbody>
                              {level2Info.map((user, i) => (
                                <tr key={i}>
                                  <td>{shortenAddress(user.address)}</td>
                                  <td>{shortenAddress(user.referrer)}</td>
                                  <td>{user.startTime}</td>
                                  <td>${user.totalStake}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-10 mt-2">
              <div className="single-accordion-item">
                {/* Card Header */}
                <div className="card-header bg-inherit border-0 p-0">
                  <h2 className="m-0">
                    <button
                      className="btn staking-btn d-block text-left w-100 py-4"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseOne3"
                    >
                      <div className="row">
                        <div className="col-12 col-md-8">
                          <div className="media flex-column flex-md-row">
                            <div className="content media-body mt-4 mt-md-0 ml-md-4">
                              <h4 className="m-0">Level 3</h4>
                              <span className="d-inline-block mt-2">
                                Users: {level3Team}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </button>
                  </h2>
                </div>
                <div
                  id="collapseOne3"
                  className="collapse"
                  data-parent="#gameon-accordion"
                >
                  {/* Card Body */}
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12 leaderboard-area">
                        <div className="table-responsive">
                        <table className="table token-content table-borderless">
                            <thead>
                              <tr>
                                <th scope="col">Address</th>
                                <th scope="col">Referrer</th>
                                <th scope="col">Start</th>
                                <th scope="col">Stake</th>
                              </tr>
                            </thead>
                            <tbody>
                              {level3Info.map((user, i) => (
                                <tr key={i}>
                                  <td>{shortenAddress(user.address)}</td>
                                  <td>{shortenAddress(user.referrer)}</td>
                                  <td>{user.startTime}</td>
                                  <td>${user.totalStake}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-10 mt-2">
              <div className="single-accordion-item">
                {/* Card Header */}
                <div className="card-header bg-inherit border-0 p-0">
                  <h2 className="m-0">
                    <button
                      className="btn staking-btn d-block text-left w-100 py-4"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseOne4"
                    >
                      <div className="row">
                        <div className="col-12 col-md-8">
                          <div className="media flex-column flex-md-row">
                            <div className="content media-body mt-4 mt-md-0 ml-md-4">
                              <h4 className="m-0">Level 4</h4>
                              <span className="d-inline-block mt-2">
                                Users: {level4Team}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </button>
                  </h2>
                </div>
                <div
                  id="collapseOne4"
                  className="collapse"
                  data-parent="#gameon-accordion"
                >
                  {/* Card Body */}
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12 leaderboard-area">
                        <div className="table-responsive">
                        <table className="table token-content table-borderless">
                            <thead>
                              <tr>
                                <th scope="col">Address</th>
                                <th scope="col">Referrer</th>
                                <th scope="col">Start</th>
                                <th scope="col">Stake</th>
                              </tr>
                            </thead>
                            <tbody>
                              {level4Info.map((user, i) => (
                                <tr key={i}>
                                  <td>{shortenAddress(user.address)}</td>
                                  <td>{shortenAddress(user.referrer)}</td>
                                  <td>{user.startTime}</td>
                                  <td>${user.totalStake}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-10 mt-2">
              <div className="single-accordion-item">
                {/* Card Header */}
                <div className="card-header bg-inherit border-0 p-0">
                  <h2 className="m-0">
                    <button
                      className="btn staking-btn d-block text-left w-100 py-4"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseOne5"
                    >
                      <div className="row">
                        <div className="col-12 col-md-8">
                          <div className="media flex-column flex-md-row">
                            <div className="content media-body mt-4 mt-md-0 ml-md-4">
                              <h4 className="m-0">Level 5</h4>
                              <span className="d-inline-block mt-2">
                                Users: {level5Team}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </button>
                  </h2>
                </div>
                <div
                  id="collapseOne5"
                  className="collapse"
                  data-parent="#gameon-accordion"
                >
                  {/* Card Body */}
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12 leaderboard-area">
                        <div className="table-responsive">
                        <table className="table token-content table-borderless">
                            <thead>
                              <tr>
                                <th scope="col">Address</th>
                                <th scope="col">Referrer</th>
                                <th scope="col">Start</th>
                                <th scope="col">Stake</th>
                              </tr>
                            </thead>
                            <tbody>
                              {level5Info.map((user, i) => (
                                <tr key={i}>
                                  <td>{shortenAddress(user.address)}</td>
                                  <td>{shortenAddress(user.referrer)}</td>
                                  <td>{user.startTime}</td>
                                  <td>${user.totalStake}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-10 mt-2">
              <div className="single-accordion-item">
                {/* Card Header */}
                <div className="card-header bg-inherit border-0 p-0">
                  <h2 className="m-0">
                    <button
                      className="btn staking-btn d-block text-left w-100 py-4"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseOne6"
                    >
                      <div className="row">
                        <div className="col-12 col-md-8">
                          <div className="media flex-column flex-md-row">
                            <div className="content media-body mt-4 mt-md-0 ml-md-4">
                              <h4 className="m-0">Level 6</h4>
                              <span className="d-inline-block mt-2">
                                Users: {level6Team}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </button>
                  </h2>
                </div>
                <div
                  id="collapseOne6"
                  className="collapse"
                  data-parent="#gameon-accordion"
                >
                  {/* Card Body */}
                  <div className="card-body">
                    <div className="row">
                      <div className="col-12 leaderboard-area">
                        <div className="table-responsive">
                        <table className="table token-content table-borderless">
                            <thead>
                              <tr>
                                <th scope="col">Address</th>
                                <th scope="col">Referrer</th>
                                <th scope="col">Start</th>
                                <th scope="col">Stake</th>
                              </tr>
                            </thead>
                            <tbody>
                              {level6Info.map((user, i) => (
                                <tr key={i}>
                                  <td>{shortenAddress(user.address)}</td>
                                  <td>{shortenAddress(user.referrer)}</td>
                                  <td>{user.startTime}</td>
                                  <td>${user.totalStake}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
}

export default Referrals;