import React, { Component } from 'react';

const data= {
    "sub_heading": "How to Participate",
    "heading": "Pledge ($PLG) Staking is completely decentralized",
    "excerpt": " Pledge Staking is a decentralized project aimed to grow Pledge community and distribute Pledge as staking rewards",
    "content_img": " /img/move.gif",

  }

const contentData=[
    {
      "id": 1,
    //   "icon": "fa-solid fa-dollar",
      "featured": "featured",
      "title": "Visit Pledge Swap",
      "content": "To take part in $PLG staking program, you need to have $PLG in your trust wallet or metamask.To have some $PLG",
      "content_1": "Goto https://plgswap.live ",
      "img": "/img/plg.gif",
      "actionBtn": "Visit Now 🚀",
      "link": "https://plgswap.live",
    },
    {
      "id": 2,
    //   "icon": "fa-brands fa-hotjar",
      "featured": "featured",
      "title": "Purchase $PLG",
      "content": "Open Link in Dapp-Browser of your wallet and connect wallet. Swap $PLG with Matic",
      "content_1": "Visit https://plgswap.live/swap",
      "img": "/img/swap.gif",
      "actionBtn": "Swap Now 🪙",
      "link": "https://plgswap.live/swap",
    },
    {
      "id": 3,
    //   "icon": "fa-solid fa-rocket",
      "featured": "featured",
      "title": "Start Staking",
      "content": "After having sufficient amount of &PLG, Goto https://plgstake.live in Dapp-Browser and stake there for amazing $PLG rewards.",
      "content_1": "Visit https://plgstake.live",
      "img": "/img/stake.gif",
      "actionBtn": "Stake Now 💰",
      "link": "https://plgstake.live",
    }
  ]  
class Content extends Component {
    state = {
        data: {},
        contentData: []
    }
    componentDidMount(){
        this.setState({
            data: data,
            contentData: contentData,
        });
    }
    render() {
        return (
            <section className="content-area">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-12 col-md-6">
                            <div className="content intro">
                            <span className="intro-text">{this.state.data.sub_heading}</span>
                            <h2>{this.state.data.heading}</h2>
                            <p>{this.state.data.excerpt}</p>
                            <ul className="list-unstyled items mt-5">
                            {this.state.contentData.map((item, idx) => {
                                    return (
                                        <li key={`cd_${idx}`} className="item">
                                            {/* Content List */}
                                            <div className="content-list d-flex align-items-center">
                                                <div className="content-icon">
                                                <span className={item.featured}>
                                                <img  src={item.img } alt="animations" />
                                                </span>
                                                </div>
                                                <div className="content-body ml-4">
                                                <h3 className="m-0">{item.title}</h3>
                                                <p className="mt-3">{item.content}</p>
                                                {/* <p className="mt-3">{item.content_1}</p> */}
                                                {/* <a className="btn btn-bordered-white btn-smaller" href={item.link}>{ item.actionBtn }</a> */}
                                                </div>
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            {/* Blockchain Animation */}
                            <div className="wrapper-animation d-none d-md-block">
                                <div className="blockchain-wrapper">
                                    <div className="pyramid">
                                        <div className="square">
                                            <div className="triangle" />
                                            <div className="triangle" />
                                            <div className="triangle" />
                                            <div className="triangle" />
                                        </div>
                                    </div>
                                    <div className="pyramid inverse">
                                        <div className="square">
                                            <div className="triangle" />
                                            <div className="triangle" />
                                            <div className="triangle" />
                                            <div className="triangle" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Content;