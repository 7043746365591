import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ethers } from "ethers";
import {useAddress, useContract, useContractRead, useContractWrite } from "@thirdweb-dev/react";
import { CONTRACT_ADDRESS } from "../../const/addresses";


const Locker = () => {
    const address = useAddress();
    const { contract } = useContract(CONTRACT_ADDRESS);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [pledgePrice, setPledgePrice] = useState(0);
    const [currentDistributed, setCurrentDistributed] = useState(false);
    const {data: currentAirdrop, isLoading: isCurrentAirdropLoading} = useContractRead(contract, "getCurrentActiveAirDropIndex");

    const {data: isCurrentClaimed, isLoading: isCurrentClaimedLoading} = useContractRead(contract, "dropDistributed", [address, currentIndex - 1]);

    useEffect(() => {
      if (!isCurrentClaimedLoading && isCurrentClaimed) {
        setCurrentDistributed(true);
      }
    }, [isCurrentClaimedLoading, isCurrentClaimed]);
    
    useEffect(() => {
      if (!isCurrentAirdropLoading && currentAirdrop) {
        const pricePledge = ethers.utils.formatUnits(currentAirdrop[1], 6);
        console.log(pricePledge);
        const formattedPrice = parseFloat(pricePledge).toFixed(4);
        setPledgePrice(formattedPrice);
        const currentIndex = parseInt(currentAirdrop[0].toNumber());
        setCurrentIndex(currentIndex + 1);
      }
    }, [isCurrentAirdropLoading, currentAirdrop]); // Added dependency array here

    const {mutateAsync : distributeAirDrop, isLoading: isDistributingAirdrop} = useContractWrite(contract, "distributeAirDrop");
    const handleClaimAirdrop = async () => {
        try {
            const { receipt } = await distributeAirDrop({args: [address]});
            const transactionHash = receipt.transactionHash;
            toast.success(`Airdrop claimed: Transaction Hash: ${transactionHash}`);
        } catch (error) {
            toast.error(`Claiming airdrop failed: ${error.reason}`);
        }
    }

    const {data: lockerInfo, isLoading: isLockerInfoLoading} = useContractRead(contract, "getLockerInfo", [address]);
    const currentDate = new Date();
    const lockers = lockerInfo ? lockerInfo[0].map((amount, index) => ({
        amount: parseFloat(ethers.utils.formatUnits(amount, 18)).toFixed(2),
        lockTime: new Date(lockerInfo[1][index].toNumber() * 1000).toLocaleString(),
        unlockTime: new Date(lockerInfo[2][index].toNumber() * 1000).toLocaleString(),
        isUnlocked: currentDate > new Date(lockerInfo[2][index].toNumber() * 1000) ? 'Yes' : 'No',
    })) : [];

    const {mutateAsync: claimUnlocked, isLoading: isClaimingUnlocked} = useContractWrite(contract, "claimUnlockedPledge");
    const handleClaimUnlocked = async (index) => {
        try {
            const { receipt } = await claimUnlocked({ args: [index] });
            const transactionHash = receipt.transactionHash;
            toast.success(`Unlocked pledge transfered to your wallet: Transaction Hash: ${transactionHash}`);
        } catch (error) {
            toast.error(`Transferring unlocked pledge failed: ${error.reason}`);
        }
    }
  return (
    <section className="leaderboard-area">
      <div className="container">
      <div className="col-12 col-xl-12 col-lg-12 mb-2">
                <div className="card tier-card prev-project-card">
                    <div className="project-content d-flex flex-column align-items-center justify-content-center">
                          <div className='d-flex justify-content-center mt-4'>    
                              <img src={"/img/drop.gif"} style={{width: '300px'}} alt="polygon" />
                          </div> 
                        <div className="content">
                            <h6 className="m-0">Current Pledge Price:{pledgePrice} USDT</h6>
                            <h6 className="mt-3 mb-0">Total Airdrops: 17</h6>
                            <h6 className="mt-3 mb-0">Active Airdrop No: {currentIndex}</h6>
                        </div>
                    </div>
                    <div className="project-footer d-flex align-items-center justify-content-center mt-4 mt-md-5">
                    <button
                        className="btn input-btn btn-smaller mt-2 mt-md-0 ml-md-7 active btn-primary"
                        onClick={handleClaimAirdrop}
                        disabled={isDistributingAirdrop || currentDistributed}
                    >
                        {currentDistributed ? 'Already Distributed' : 'Claim Airdrop'}
                    </button>
                </div>
                </div>
            </div>
        <div className="row">
          <div className="col-12">
            <div className="table-responsive">
              <table className="table token-content table-borderless responsive-table">
                <thead>
                  <tr>
                    <th scope="col" style={{ width: '5%' }}>SR.</th>
                    <th scope="col" style={{ width: '12%' }}>Amount</th>
                    <th scope="col" style={{ width: '18%' }}>Lock-Date</th>
                    <th scope="col" style={{ width: '18%' }}>Unlock-Date</th>
                    <th scope="col" style={{ width: '13%' }}>Unlocked?</th>
                    <th scope="col" style={{ width: '14%' }}>Action</th>

                  </tr>
                </thead>
                <tbody>
                  {lockers.map((item, index) => (
                    <tr key={index}>
                      <td style={{ width: '5%' }}>{index + 1}</td>
                      <td style={{ width: '12%' }}>{item.amount} $PLG</td>
                      <td style={{ width: '18%', fontSize: '9.5px', padding: '20px'  }}>{item.lockTime}</td>
                      <td style={{ width: '18%', fontSize: '9.5px', padding: '20px'  }}>{item.unlockTime}</td>
                      <td style={{ width: '13%' }}>{item.isUnlocked}</td>
                      <td style={{ width: '14%' }}>
                        <button
                          className="btn btn-bordered-pill-green"
                          onClick={() => (handleClaimUnlocked(index))}
                          disabled={ isClaimingUnlocked} // Disable button if there's a gas error or input error
                          >
                          Withdraw
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Locker;