import { useAddress, useContract, useContractRead } from "@thirdweb-dev/react";
import { toast } from 'react-toastify';
import { ethers } from 'ethers';
import 'react-toastify/dist/ReactToastify.css';
import { CONTRACT_ADDRESS } from "../../const/addresses";
import React, { useState, useEffect } from 'react';

const initData = {
    sub_heading: "Exclusive",
    heading: "My Activites",
    btn: "View Tokenomics",
    actionBtn: "Load More"
}

const Dashboard = () => {
    const shortenAddress = (address) => {
        if (address && address.length > 8) {
            const start = address.slice(0, 4);
            const end = address.slice(-4);
            return `${start}...${end}`;
        }
        return address;
    }
    const address = useAddress();
    const { contract } = useContract(CONTRACT_ADDRESS);
    const { data, isLoading } = useContractRead(contract, "userInfo", [address]);
    

    const { data: level1count, isLoading: isLoadingLevel1Count } = useContractRead(contract, "getLevel1Team", [address]);
    const formattedLevel1Count = level1count? level1count.toString() : 0;

    const [ level1Team, setLevel1Team ] = useState(0);

    useEffect(() => {
      setLevel1Team(formattedLevel1Count);
    }, [isLoadingLevel1Count ,formattedLevel1Count]);

    const { data: totalTeamCount, isLoading: isLoadingTotalTeamCount } = useContractRead(contract, "getTotalTeam" , [address]);
    const formattedTotalTeamCount = totalTeamCount? totalTeamCount.toString() : 0;

    const [TeamCount, setTeamCount] = useState(0);
    useEffect(() => {
        setTeamCount(formattedTotalTeamCount);
    }, [isLoadingTotalTeamCount, formattedTotalTeamCount]);

    
    const { data: getReferralStake, isLoading: isLoadingReferralsStake } = useContractRead(contract, "getReferralStake", [address]);
    const formattedReferralStake = getReferralStake? getReferralStake.toString() / 1000000 : 0;

    const [referralStake, setReferralStake] = useState(0);

    useEffect(() => {
        setReferralStake(formattedReferralStake);
    }, [isLoadingReferralsStake, formattedReferralStake]);

    const [myPool, setMyPool] = useState(0);
   
    const [myLevel, setMyLevel] = useState(0);
    const [myRef, setMyRef] = useState(null);
    const [myStake, setMyStake] = useState(0);
    const [referralLink, setReferralLink] = useState('');

    useEffect(() => {
      if (address) {
        const newReferralLink = `${window.location.origin}/?referrer=${address}`;
        setReferralLink(newReferralLink);
      }
    }, [address]);

    const copyToClipboard = async () => {
      try {
        await navigator.clipboard.writeText(referralLink);
        toast.success('Referral link copied to clipboard!');
      } catch (err) {
        toast.error('Failed to copy link.');
      }
    };

    useEffect(() => {
        if (data) {
            const currentPool = parseInt(data.currentPool);
            setMyPool(currentPool);
            const myRef = data.referrer;
            setMyRef(myRef);
            const myStake = parseInt(data.totalStake) / 1000000;
            setMyStake(myStake);
        }
    }, [isLoading, data]);

    
    const { data: levelData, isLoading: isLevelDataLoading } = useContractRead(contract, "getUserLevel", [address]);
    useEffect(() => {
        if (levelData) {
            const myLevel = parseInt(levelData);
            setMyLevel(myLevel);
        }
    }, [isLevelDataLoading, levelData]);
    
    const { data: levelUserInfo, isLoading: isLoadingLevelUserInfo } = useContractRead(contract, "getLevelUsersInfo", [address]);
    const [totalTeamStake, setTotalTeamStake] = useState(["No Data"]);

    useEffect(() => {
        if (!isLoadingLevelUserInfo && levelUserInfo) {
            const totalTeamStake = levelUserInfo.reduce((total, level) => {
                return total + level.reduce((levelTotal, user) => {
                    if (user[0] !== address) {
                        return levelTotal + parseInt(ethers.utils.formatUnits(user[3], 6));
                    } else {
                        return levelTotal;
                    }
                }, 0);
            }, 0);

            setTotalTeamStake(totalTeamStake);
        }
    }, [isLoadingLevelUserInfo, levelUserInfo, address]);

  const {data: rewardInfo, isLoading: isLoadingRewardInfo} = useContractRead(contract, "rewardInfo", [address]);
  const [availableBalance, setAvailableBalance] = useState(0);
  const [levelRewards, setLevelRewards] = useState(0);
  const [referralRewards, setReferralRewards] = useState(0);
  const [roundRewards, setRoundRewards] = useState(0);
  const [winningRewards, setWinningRewards] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);
  const [totalStakeRewards, setTotalStakeRewards] = useState(0);
  const [unStakeAmounts, setUnStakeAmounts] = useState(0);
  const [withdrawnBalance, setWithdrawnBalance] = useState(0);
  useEffect(() => {
      if (!isLoadingRewardInfo && rewardInfo) {
          const availableBalance = ethers.utils.formatUnits(rewardInfo.availableBalance, 6);
          setAvailableBalance(availableBalance);
          const levelRewards = ethers.utils.formatUnits(rewardInfo.levelRewards, 6);
          setLevelRewards(levelRewards);
          const referralRewards = ethers.utils.formatUnits(rewardInfo.referralRewards, 6);
          setReferralRewards(referralRewards);
          const roundRewards = ethers.utils.formatUnits(rewardInfo.roundRewards, 6);
          setRoundRewards(roundRewards);
          const totalBalance = ethers.utils.formatUnits(rewardInfo.totalBalance, 6);
          setTotalBalance(totalBalance);
          const winningRewards = ethers.utils.formatUnits(rewardInfo.winningRewards, 6);
          setWinningRewards(winningRewards);
          const totalStakeRewards = ethers.utils.formatUnits(rewardInfo.totalStakeRewards, 6);
          setTotalStakeRewards(totalStakeRewards);
          const unStakeAmounts = ethers.utils.formatUnits(rewardInfo.unStakeAmounts, 6);
          setUnStakeAmounts(unStakeAmounts);
          const withdrawnBalance = ethers.utils.formatUnits(rewardInfo.withdrawnBalance, 6);
          setWithdrawnBalance(withdrawnBalance);
      }
  }, [isLoadingRewardInfo, rewardInfo]);


    return (
      <section className="explore-area prev-project-area">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {/* Intro */}
              <div className="intro d-flex justify-content-between align-items-end m-0">
                <div className="intro-content">
                  <h3 className="mt-3 mb-0">{initData.heading}</h3>
                </div>
              </div>
            </div>
          </div>
          <div className="row items">
            <div className="col-12 col-xl-6 col-lg-6 item">
              <div className="card tier-card prev-project-card">
                <h4 className="m-0 mb-2">My Referral Link</h4>
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    value={referralLink}
                    disabled
                  />
                  <div className="input-group-append">
                    <button
                      className="btn btn-outline-secondary btn-smaller rounded-right"
                      type="button"
                      onClick={copyToClipboard}
                    >
                      Copy
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-6 col-lg-6 item">
              <div className="card tier-card prev-project-card">
                <div className="project-content d-md-flex flex-column flex-md-row align-items-center justify-content-md-between">
                  <div className="item-header d-flex align-items-center mb-md-0">
                    <img
                      className="card-img-top avatar-max-lg"
                      src={"/img/icons/award-medal.png"}
                      alt=""
                    />
                    <div className="content ml-4">
                      <h4 className="m-0">{myPool}</h4>
                      <h6 className="mt-3 mb-0">My Pool</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-6 col-lg-6 item">
              <div className="card tier-card prev-project-card">
                <div className="project-content d-md-flex flex-column flex-md-row align-items-center justify-content-md-between">
                  <div className="item-header d-flex align-items-center mb-md-0">
                    <img
                      className="card-img-top avatar-max-lg"
                      src={"/img/icons/level.png"}
                      alt=""
                    />
                    <div className="content ml-4">
                      <h4 className="m-0">{myLevel}</h4>
                      <h6 className="mt-3 mb-0">My Level</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-6 col-lg-6 item">
              <div className="card tier-card prev-project-card">
                <div className="project-content d-md-flex flex-column flex-md-row align-items-center justify-content-md-between">
                  <div className="item-header d-flex align-items-center mb-md-0">
                    <img
                      className="card-img-top avatar-max-lg"
                      src={"/img/icons/stake.png"}
                      alt=""
                    />
                    <div className="content ml-4">
                      <h4 className="m-0">${myStake}</h4>
                      <h6 className="mt-3 mb-0">My Total Stake</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-6 col-lg-6 item">
              <div className="card tier-card prev-project-card">
                <div className="project-content d-md-flex flex-column flex-md-row align-items-center justify-content-md-between">
                  <div className="item-header d-flex align-items-center mb-md-0">
                    <img
                      className="card-img-top avatar-max-lg"
                      src={"/img/icons/referrer.png"}
                      alt=""
                    />
                    <div className="content ml-4">
                      <h4 className="m-0">{shortenAddress(myRef)}</h4>
                      <h6 className="mt-3 mb-0">My Referrer</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-6 col-lg-6 item">
              <div className="card tier-card prev-project-card">
                <div className="project-content d-md-flex flex-column flex-md-row align-items-center justify-content-md-between">
                  <div className="item-header d-flex align-items-center mb-md-0">
                    <img
                      className="card-img-top avatar-max-lg "
                      src={"/img/icons/tree.png"}
                      alt=""
                    />
                    <div className="content ml-4">
                      <h4 className="m-0">$ {referralRewards}</h4>
                      <h6 className="mt-3 mb-0">My Referral Rewards</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-6 col-lg-6 item">
              <div className="card tier-card prev-project-card">
                <div className="project-content d-md-flex flex-column flex-md-row align-items-center justify-content-md-between">
                  <div className="item-header d-flex align-items-center mb-md-0">
                    <img
                      className="card-img-top avatar-max-lg "
                      src={"/img/icons/round.png"}
                      alt=""
                    />
                    <div className="content ml-4">
                      <h4 className="m-0">$ {roundRewards}</h4>
                      <h6 className="mt-3 mb-0">My Round Rewards</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-6 col-lg-6 item">
              <div className="card tier-card prev-project-card">
                <div className="project-content d-md-flex flex-column flex-md-row align-items-center justify-content-md-between">
                  <div className="item-header d-flex align-items-center mb-md-0">
                    <img
                      className="card-img-top avatar-max-lg"
                      src={"/img/icons/levels.png"}
                      alt=""
                    />
                    <div className="content ml-4">
                      <h4 className="m-0">$ {levelRewards}</h4>
                      <h6 className="mt-3 mb-0">My Level Rewards</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-6 col-lg-6 item">
              <div className="card tier-card prev-project-card">
                <div className="project-content d-md-flex flex-column flex-md-row align-items-center justify-content-md-between">
                  <div className="item-header d-flex align-items-center mb-md-0">
                    <img
                      className="card-img-top avatar-max-lg"
                      src={"/img/icons/unstake.png"}
                      alt=""
                    />
                    <div className="content ml-4">
                      <h4 className="m-0">$ {unStakeAmounts}</h4>
                      <h6 className="mt-3 mb-0">UnStake Amounts</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-6 col-lg-6 item">
              <div className="card tier-card prev-project-card">
                <div className="project-content d-md-flex flex-column flex-md-row align-items-center justify-content-md-between">
                  <div className="item-header d-flex align-items-center mb-md-0">
                    <img
                      className="card-img-top avatar-max-lg"
                      src={"/img/icons/staked.png"}
                      alt=""
                    />
                    <div className="content ml-4">
                      <h4 className="m-0">$ {totalStakeRewards}</h4>
                      <h6 className="mt-3 mb-0">Total Stake Rewards</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-6 col-lg-6 item">
              <div className="card tier-card prev-project-card">
                <div className="project-content d-md-flex flex-column flex-md-row align-items-center justify-content-md-between">
                  <div className="item-header d-flex align-items-center mb-md-0">
                    <img
                      className="card-img-top avatar-max-lg"
                      src={"/img/icons/gamewin.svg"}
                      alt=""
                    />
                    <div className="content ml-4">
                      <h4 className="m-0">$ {winningRewards}</h4>
                      <h6 className="mt-3 mb-0">Game Winnings</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        
            <div className="col-12 col-xl-6 col-lg-6 item">
              <div className="card tier-card prev-project-card">
                <div className="project-content d-md-flex flex-column flex-md-row align-items-center justify-content-md-between">
                  <div className="item-header d-flex align-items-center mb-md-0">
                    <img
                      className="card-img-top avatar-max-lg"
                      src={"/img/icons/total.png"}
                      alt=""
                    />
                    <div className="content ml-4">
                      <h4 className="m-0">$ {totalBalance}</h4>
                      <h6 className="mt-3 mb-0">My Total Rewards</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-6 col-lg-6 item">
              <div className="card tier-card prev-project-card">
                <div className="project-content d-md-flex flex-column flex-md-row align-items-center justify-content-md-between">
                  <div className="item-header d-flex align-items-center mb-md-0">
                    <img
                      className="card-img-top avatar-max-lg"
                      src={"/img/icons/wallet.png"}
                      alt=""
                    />
                    <div className="content ml-4">
                      <h4 className="m-0">$ {availableBalance}</h4>
                      <h6 className="mt-3 mb-0">My Available Balance</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-6 col-lg-6 item">
              <div className="card tier-card prev-project-card">
                <div className="project-content d-md-flex flex-column flex-md-row align-items-center justify-content-md-between">
                  <div className="item-header d-flex align-items-center mb-md-0">
                    <img
                      className="card-img-top avatar-max-lg"
                      src={"/img/icons/withdraw.png"}
                      alt=""
                    />
                    <div className="content ml-4">
                      <h4 className="m-0">$ {withdrawnBalance}</h4>
                      <h6 className="mt-3 mb-0">My Withdrawn Balance</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-6 col-lg-6 item">
              <div className="card tier-card prev-project-card">
                <div className="project-content d-md-flex flex-column flex-md-row align-items-center justify-content-md-between">
                  <div className="item-header d-flex align-items-center mb-md-0">
                    <img
                      className="card-img-top avatar-max-lg"
                      src={"/img/icons/teamdirect.svg"}
                      alt=""
                    />
                    <div className="content ml-4">
                      <h4 className="m-0">{level1Team}</h4>
                      <h6 className="mt-3 mb-0">My Direct Referrals</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-6 col-lg-6 item">
              <div className="card tier-card prev-project-card">
                <div className="project-content d-md-flex flex-column flex-md-row align-items-center justify-content-md-between">
                  <div className="item-header d-flex align-items-center mb-md-0">
                    <img
                      className="card-img-top avatar-max-lg"
                      src={"/img/icons/team2.svg"}
                      alt=""
                    />
                    <div className="content ml-4">
                      <h4 className="m-0">{TeamCount}</h4>
                      <h6 className="mt-3 mb-0">My Total Team</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-6 col-lg-6 item">
              <div className="card tier-card prev-project-card">
                <div className="project-content d-md-flex flex-column flex-md-row align-items-center justify-content-md-between">
                  <div className="item-header d-flex align-items-center mb-md-0">
                    <img
                      className="card-img-top avatar-max-lg"
                      src={"/img/icons/money.svg"}
                      alt=""
                    />
                    <div className="content ml-4">
                      <h4 className="m-0">${referralStake}</h4>
                      <h6 className="mt-3 mb-0">My Direct Team Stake</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-6 col-lg-6 item">
              <div className="card tier-card prev-project-card">
                <div className="project-content d-md-flex flex-column flex-md-row align-items-center justify-content-md-between">
                  <div className="item-header d-flex align-items-center mb-md-0">
                    <img
                      className="card-img-top avatar-max-lg"
                      src={"/img/icons/team-stake.png"}
                      alt=""
                    />
                    <div className="content ml-4">
                      <h4 className="m-0">${totalTeamStake}</h4>
                      <h6 className="mt-3 mb-0">My Total Team Stake</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

export default Dashboard;