import React from 'react';
import { ToastContainer } from 'react-toastify';
import MyRouts from './routers/routes';

function App() {
  return (
    <div>
      <ToastContainer />
      <MyRouts />
    </div>
  );
}

export default App;