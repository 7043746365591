import React, { useCallback, useEffect, useState } from 'react';
import { ethers } from 'ethers';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAddress, useContract, useContractRead, useContractWrite, useBalance} from "@thirdweb-dev/react";
import { CONTRACT_ADDRESS, USDC_ADDRESS, ORACLE_ADDRESS } from "../../const/addresses";

const Release = () => {
    const address = useAddress();
    const { contract } = useContract(CONTRACT_ADDRESS);
    const { contract: oracleContract } = useContract(ORACLE_ADDRESS);

    const { mutateAsync: estimateAmountOut} = useContractWrite(oracleContract, "estimateAmountOut");

    const { data: nativeBalance, isLoading: isNativeBalanceLoading } = useBalance();
    const [nativeValue, setNativeValue] = useState(0);
    useEffect(() => {
        if(!isNativeBalanceLoading && nativeBalance) {
            const myNativeBalance = nativeBalance.displayValue;
            const myMatic = parseFloat(myNativeBalance).toFixed(3);
            setNativeValue(myMatic);
        }
    }, [isNativeBalanceLoading,nativeBalance]);


    const { data: rewardInfo, isLoading: isLoadingRewardInfo } = useContractRead(contract, "rewardInfo", [address]);
    const [availableBalance, setAvailableBalance] = useState(0);
    const [withdrawnBalance, setWithdrawnBalance] = useState(0);
    useEffect(() => {
        if (!isLoadingRewardInfo && rewardInfo) {
            const availableBalance = ethers.utils.formatUnits(rewardInfo.availableBalance, 6);
            setAvailableBalance(availableBalance);
            const withdrawnBalance = ethers.utils.formatUnits(rewardInfo.withdrawnBalance, 6);
            setWithdrawnBalance(withdrawnBalance);
        }
    }, [isLoadingRewardInfo, rewardInfo]);

    

    const [inputError, setInputError] = useState('');

    const handleInputChange = (e) => {
        const inputAmount = e.target.value;
        setUsdcAmount(inputAmount);
        if (parseFloat(inputAmount) > parseFloat(availableBalance)) {
            setInputError('Input amount exceeds available balance');
        } else {
            setInputError('');
        }
    };

    const [gasError, setGasError] = useState('');
    useEffect(() => {
        const checkGasFee = async () => {
            const requiredGasFee = ethers.utils.parseUnits('0.01', 'ether'); // Gas fee in wei for 0.01 Matic
            if (nativeBalance && nativeBalance.value.lt(requiredGasFee)) {
                setGasError("Insufficient Matic for the transaction fee. You need at least 0.01 Matic");
            } else {
                setGasError('');
            }
        };
        checkGasFee();
    }, [nativeBalance]);
    const { mutateAsync: withdraw, isLoading: isLoadingWithdraw } = useContractWrite(contract, "withdraw");
    const [usdcAmount, setUsdcAmount] = useState('');

    const handleWithdraw = async () => {
        try {
            const inputAmount = ethers.utils.parseUnits(usdcAmount, 6);
            const { receipt } = await withdraw({ args: [inputAmount] });
            const transactionHash = receipt.transactionHash;
            toast.success(`Withdraw successful: Transaction Hash: ${transactionHash}`);
        } catch (error) {
            toast.error(`Withdraw failed: ${error.reason}`);
        }
    }
    const [amountOut, setAmountOut] = useState('');

     const handleAmountOut = useCallback(async () => {
      try {
          const amountIn = ethers.utils.parseUnits(usdcAmount, 6);
          const outPut = await estimateAmountOut({ args: [USDC_ADDRESS, amountIn, 5]});
          const plgAmount = ethers.utils.formatUnits(outPut, 18);
          const amountOut = parseFloat(plgAmount).toFixed(2);
          
          if (usdcAmount !== '') {
              setAmountOut(amountOut);
          } else {
              setAmountOut('');
          }
      } catch (error) {
          console.error("Failed to estimate amount out:", error);
      }
    }, [usdcAmount, estimateAmountOut]);

    useEffect(() => {
      if(usdcAmount !== '') {
        handleAmountOut();
      }
    }, [usdcAmount, handleAmountOut]);

    return (
      <section className="staking-area">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-7">
              <div className="card tier-card prev-project-card ">
              <div className='d-flex justify-content-center mt-4'>    
                    <img src={"/img/withdraw.gif"} style={{width: '300px', filter: 'brightness(85%)'}} alt="polygon" />
                </div>
                <h4 className="m-0">Withdraw</h4>
                <div className="tab-content mt-md-3" id="myTabContent">
                  <div className="staking-tab-content">
                    {/* Info Box */}
                    <div className="info-box d-flex justify-content-between">
                      <div className="info-left">
                        <ul className="list-unstyled">
                          <li>
                            <strong>Available balance:</strong> {availableBalance} $
                          </li>
                          <li>
                            <strong>Withdrawn balance:</strong> {withdrawnBalance} $
                          </li>
                          <li>
                            <strong>Wallet Gas balance:</strong> {nativeValue} Matic
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="input-box my-4">
                  <div className="input-area d-flex flex-column flex-md-row mb-3">
                    <div className="input-text">
                      <input type="text"
                      max={availableBalance}
                      placeholder="0"
                      onChange={handleInputChange}
                      readOnly={isLoadingRewardInfo}
                      />
                      <a>USDT</a>
                    </div>
                      <p className="text-center">
                      ≈ <span>{amountOut} $PLG</span>
                      </p>

                    <button 
                        className="btn input-btn mt-2 mt-md-0 ml-md-3"
                        onClick={handleWithdraw}
                        disabled={inputError || gasError || isLoadingWithdraw}
                    >
                        {""}
                      Withdraw
                    </button>
                  </div>
                </div>
                {inputError && <div className="text-danger">{inputError}</div>}
                {gasError && <div className="text-danger">{gasError}</div>}
                <span className='text-success'>*No fee on Withdrawal</span>
                <span className="mt-3 text-danger"><strong>Min. 10$ and Max. 5000$ at a time</strong></span>
              </div>
            </div>
          </div>
        </div>
      </section>
    );

}


export default Release;
