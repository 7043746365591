import React, { useEffect, useState } from "react";
import { ConnectWallet } from "@thirdweb-dev/react";
import { Polygon } from "@thirdweb-dev/chains";

const supportedTokens = {
    // use chain id of the network as key and pass an array of tokens to show
    // you can directly pass the number or import the chain object from @thirdweb-dev/chains to get the chain id
    [Polygon.chainId]: [
      {
        address: "0x036392E6E21920aC9B864512eC7aeA7A8353ff56", // token contract address
        name: "Pledge",
        symbol: "$PLG",
        icon: "https://polygonscan.com/token/images/pledge_32.png",
      },
    ],
  };


const Header = () => {
    const [ready, setReady] = useState(false);

    useEffect(() => {
      setReady(true);
    }, []);
    return (
        <header id="header">
            {/* Navbar */}
            <nav data-aos="zoom-out" data-aos-delay={800} className="navbar gameon-navbar navbar-expand">
                <div className="container header">
                    {/* Logo */}
                    <a className="navbar-brand" href="/">
                        <img src="/img/logo.png" alt="Brand Logo" />
                    </a>
                    <div className="ml-auto" />
                    {/* Navbar Nav */}
                    <ul className="navbar-nav items mx-auto">
                        <li className="nav-item">
                            <a href="/" className="nav-link">Home</a>
                        </li>
                        <li className="nav-item">
                            <a href="/dashboard" className="nav-link">Dashboard</a>
                        </li>
                        <li className="nav-item">
                            <a href="/stake" className="nav-link">Stake</a>
                        </li>
                        <li className="nav-item">
                            <a href="/release" className="nav-link">Release</a>
                        </li>
                        <li className="nav-item">
                            <a href="/referral" className="nav-link">Referral</a>
                        </li>
                        <li className="nav-item">
                            <a href="/history" className="nav-link">History</a>
                        </li>
                        <li className="nav-item">
                            <a href="/pool" className="nav-link">Pool</a>
                        </li>
                        <li className="nav-item">
                            <a href="/locker" className="nav-link">Locker</a>
                        </li>
                        <li className="nav-item">
                            <a href="/game" className="nav-link">Game</a>
                        </li>
                    </ul>
                    {/* Navbar Icons */}
                    {/* Navbar Toggler */}                    
                    {/* Navbar Action Button */}
                    <ul className="navbar-nav action">
                        <li className="nav-item ml-2">
                        <ConnectWallet
                                btnTitle="Connect Wallet"
                                switchToActiveChain={true}
                                modalSize={"compact"}
                                showThirdwebBranding={false}
                                autoConnect={true}
                                supportedTokens={supportedTokens}
                            />
                        </li>
                    </ul>
                    <ul className="navbar-nav toggle">
                        <li className="nav-item">
                            <a href="#" className="nav-link" data-toggle="modal" data-target="#menu">
                                <i className="icon-menu m-0" />
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
        </header>
    );
};

export default Header;